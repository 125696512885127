import { useState, useEffect } from 'react';
import { Button, Space } from 'antd';
import type { CollapseProps } from 'antd';
import { DomainGetParams, useDomainsSelectLocationsGet } from '../../../../../hooks/domain';
import { useAuthState } from '../../../../../store/auth';
import { useClientId } from '../../../../../hooks/client';
import Loading from '../../../../Common/Loading/index';
import DomainModal from '../DomainModal';
import CreateLocation from '../CreateLocation';
import RemoveDomain from '../RemoveDomain';
import TopPanel from '../../../../Common/TopPanel/index';
import { UserRoles, isRoleEnough } from '../../../../../enums/user';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { useMessageError } from '../../../../../hooks/common';
import Collapse from '../../../../Common/Collapse';
import Table from '../Table';
import Counter from '../../../../Common/Counter';

import styles from './index.module.scss';

export default function LocationList() {
  const { user } = useAuthState();
  const clientId = user?.locationAdmin?.client.id || user?.manager?.client.id;
  const getClient = useClientId();
  const domainsSelectLocationsGet = useDomainsSelectLocationsGet();
  const [,, paramsWithoutTableProps] = useSearchParams();
  const [domainModal, setDomainModal] = useState(false);
  const [locationsModal, setLocationsModal] = useState(false);
  const [updateLocation, setUpdateLocation] = useState(false);
  const [activeDomain, setActiveDomain] = useState<string | null>(null);
  const isAdmin = isRoleEnough(user?.role, UserRoles.CLIENT_ADMIN);

  const fetch = () => {
    const params: DomainGetParams = { clients: clientId || '', ...paramsWithoutTableProps };

    if (clientId) {
      getClient.fetch(undefined, clientId);
    }

    domainsSelectLocationsGet.fetch(params);
  };

  useEffect(fetch, [paramsWithoutTableProps]);

  useMessageError([domainsSelectLocationsGet, getClient]);

  const items: CollapseProps['items'] = domainsSelectLocationsGet?.data?.map((item) => ({
    key: item.id,
    label: (
      <Space>
        {item.title}
        <Counter count={item.locationsCount} />
      </Space>
    ),
    extra: isAdmin && (
      <>
        <Button
          className={styles.btn}
          onClick={(event) => {
            event.stopPropagation();
            setLocationsModal(true);
            setActiveDomain(item.id);
          }}
        >
          Add location
        </Button>
        {getClient.data && (
          <RemoveDomain clientId={clientId} client={getClient.data} fetch={fetch} domainId={item.id} />
        )}
      </>
    ),
    children: (
      <Table
        id={item.id}
        updateLocation={updateLocation}
        setUpdateLocation={setUpdateLocation}
        clientId={clientId || ''}
      />
    ),
  }));

  return domainsSelectLocationsGet.loading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.wrapper}>
      <TopPanel
        padding
        title="Locations"
        rightContent={
          isAdmin && (
            <Button type="primary" onClick={() => setDomainModal(true)}>
              Add surgeon
            </Button>
          )
        }
      />
      {getClient.data && (
        <DomainModal domainModal={domainModal} setDomainModal={setDomainModal} fetch={fetch} client={getClient.data} />
      )}

      <CreateLocation
        locationsModal={locationsModal}
        setLocationsModal={setLocationsModal}
        setUpdateLocation={setUpdateLocation}
        clientId={clientId}
        activeDomain={activeDomain}
      />

      <Collapse accordion items={items} />
    </div>
  );
}
