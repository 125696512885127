import { useMemo, useState } from 'react';
import clsx from 'clsx';
import SettingsProvider, { useSettingsContext } from './Context';
import TopBar from './TopBar';
import CommonTabs, { TabPaneItem } from '../../../Common/Tabs';
import Details from './Details';
import Inductions from './Inductions';
import Documents from './Documents';
import SaveModal from './SaveModal';
import Loading from '../../../Common/Loading';
import { useAuthState } from '../../../../store/auth';
import { UserRoles } from '../../../../enums/user';
import Employment from './Employment';
import Teams from './Teams';

import styles from './index.module.scss';

function Wrapper() {
  const {
    isEdit, isChanging, setActiveTab, isAdmin, isVerified, applicant, setSaveModalOpen,
  } = useSettingsContext();
  const [activeKey, setActiveKey] = useState('details');

  const onChange = (key: string) => {
    if (isAdmin && !isVerified) {
      setActiveKey(key);

      return;
    }

    if (isChanging) {
      setActiveTab(() => activeKey);
      setSaveModalOpen(true);
    } else {
      setActiveKey(key);
    }
  };

  const tabList: TabPaneItem[] = [
    {
      label: 'Assistant Details',
      key: 'details',

      children: <Details />,
    },
    {
      label: 'Documents',
      key: 'documents',
      children: <Documents />,
    },
    {
      label: 'Inductions',
      key: 'inductions',
      children: <Inductions />,
    },
    {
      label: 'Employment info',
      key: 'employment',
      children: <Employment />,
    },
    {
      label: 'Teams',
      key: 'teams',
      children: <Teams />,
    },
  ];

  const items = useMemo(() => tabList.filter((item) => (isAdmin ? item : item.key !== 'teams')), [isEdit]);

  return (
    <>
      {applicant ? (
        <TopBar />
      ) : (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <SaveModal />
      <CommonTabs
        /* eslint-disable-next-line max-len */
        items={items.filter((item) => (isAdmin && !isVerified ? item.key !== 'inductions' && item.key !== 'teams' : item))}
        destroyInactiveTabPane={(isAdmin && isVerified) || !isAdmin}
        activeKey={activeKey}
        onChange={onChange}
      />
    </>
  );
}

export default function Profile(): React.ReactNode | null {
  const { user } = useAuthState();
  const isApplicant = user && user.role ? user?.role === UserRoles.APPLICANT : true;

  return (
    <div className={clsx(styles.wrapper, { [styles.maxWidth]: isApplicant })}>
      <SettingsProvider>
        <Wrapper />
      </SettingsProvider>
    </div>
  );
}
