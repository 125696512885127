import { useNavigate } from 'react-router-dom';
import { UserSwitchOutlined, FileSearchOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import { useAuthState } from '../../../../store/auth';
import RadioButton, { RadioItem } from '../../../Common/Tabs/RadioButton';
import { paramsToString } from '../../../Layout/Menu';
import { getRouteParams } from '../../../../routes';
import styles from './index.module.scss';

interface RadioSwitchProps {
  defaultActive: string;
}

export default function RadioSwitch({ defaultActive }: RadioSwitchProps) {
  const navigate = useNavigate();
  const { user } = useAuthState();

  const list: RadioItem[] = [
    {
      key: 'applicants',
      icon: <FileSearchOutlined />,
      content: 'Assistants',
    },
    {
      key: 'incomplete-registrations',
      icon: <UserSwitchOutlined />,
      content: 'Incomplete Registrations',
    },
    {
      key: 'teams',
      icon: <UsergroupDeleteOutlined />,
      content: 'Teams',
    },
  ];

  return (
    <RadioButton
      className={styles.tabs}
      defaultActive={defaultActive}
      items={list}
      onChange={(key, beforeState) => {
        if (beforeState !== key) {
          if (key === list[0].key) {
            navigate(`/applicants${paramsToString(user, getRouteParams('applicants', user))}`);
          }

          if (key === list[1].key) {
            navigate('/applicants/incomplete-registrations');
          }
          if (key === list[2].key) {
            navigate('/teams');
          }
        }
      }}
    />
  );
}
