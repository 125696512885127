import React, { useEffect, useMemo } from 'react';
import { Badge, Button, Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import type { ProColumns } from '@ant-design/pro-components';
import { ProCoreActionType } from '@ant-design/pro-utils/es/typing';
import Table from '../../../Common/Table';
import { getBudgeColor } from '../../../Common/Badge';
import { PagingDataResponse } from '../../../../hooks/fetch';
import { useAuthState } from '../../../../store/auth';
import { isRoleEnough, UserRoles } from '../../../../enums/user';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import {
  User,
  useUserUpdate,
  useUsersGet,
  UsersGetParams,
  usersGetParamList,
  useUserDelete,
} from '../../../../hooks/user';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';

import styles from './index.module.scss';

export interface ActionProps<D> {
  dom: React.ReactNode;
  entity: D;
  index: number;
  action: ProCoreActionType | undefined;
}

interface UserRow extends User {
  url: string;
}

const columns: ProColumns<UserRow>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, user) => <NavLink to={user.url}>{`${user.firstName} ${user.lastName}`}</NavLink>,
  },
  {
    title: 'Date created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date) => date && new Date(date as string).toLocaleDateString('en-GB'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (node, entity) => (
      <Badge className={styles.status} color={getBudgeColor(entity.status)} text={entity.status} />
    ),
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (text, entity) => <NavLink to={`tel:${entity?.phone}`}>{entity.phone}</NavLink>,
  },
  {
    title: 'Email login',
    key: 'email',
    dataIndex: 'email',
    render: (text, entity) => <NavLink to={`mailto:${entity?.email}`}>{entity.email}</NavLink>,
  },
];

export default function AdminList(): React.ReactNode {
  const { open, contextHolder } = useSimpleModal();
  const users = useUsersGet<PagingDataResponse<UserRow>>();
  const userUpdate = useUserUpdate();
  const userDelete = useUserDelete();
  const { user } = useAuthState();
  const isRoot = isRoleEnough(user?.role, UserRoles.ROOT);
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParamsString = searchParams.toString();
  const urlParams = urlParamsString.length ? `?${urlParamsString}` : '';

  const getUsers = () => {
    const props: UsersGetParams = {};

    usersGetParamList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[key] = value.length === 1 ? value[0] : value;
      }
    });
    if (!searchParams.get('statuses')) {
      props.statuses = ['active'];
    }
    props.roles = [UserRoles.LOKEM_ADMIN, UserRoles.ROOT];
    users.fetch(props);
  };

  const usersData = useMemo<PagingDataResponse<UserRow>>(() => {
    if (users.data && users.data.data && users.data.data.length) {
      return {
        ...users.data,
        data: users.data.data.map((items) => ({
          ...items,
          url: items.id + urlParams,
        })),
      } as PagingDataResponse<UserRow>;
    }

    return users.data as PagingDataResponse<UserRow>;
  }, [users.data?.data, urlParams]);

  useEffect(() => {
    if (userUpdate?.data && !userUpdate?.error && users) {
      getUsers();
    }
  }, [userUpdate?.data]);

  useEffect(() => {
    if (userDelete?.data && !userDelete?.error && users) {
      getUsers();
    }
  }, [userDelete?.data]);

  useEffect(getUsers, [searchParams]);

  useEffect(() => {
    if (usersData && !searchParams.get('statuses')) {
      setSearchParams({ statuses: 'active' });
    }
  }, [usersData]);

  const toolBarRender = () => [
    <NavLink key="create" to="/admins/create">
      <Button type="primary" ghost>
        Add admin
      </Button>
    </NavLink>,
  ];

  const actions = ({ entity }: ActionProps<UserRow>) => (
    <>
      <Tooltip title={entity?.status === 'active' ? 'Deactivate' : 'Activate'}>
        <Button
          type="link"
          className={styles.btn}
          onClick={(e) => {
            e.preventDefault();
            const isActive = entity?.status === 'active';
            const actionName = isActive ? 'Deactivate' : 'Activate';

            open({
              icon: isActive ? (
                <StopOutlined style={{ color: '#FF4D4F' }} />
              ) : (
                <CheckCircleOutlined style={{ color: COLOR_PRIMARY }} />
              ),
              title: `${actionName} admin`,
              content: `Are you sure you want to ${actionName.toLowerCase()} this admin (${entity.firstName} ${
                entity.lastName
              })?`,
              cancelText: 'Cancel',
              okText: actionName,
              onOk: () => userUpdate.fetch({ status: isActive ? 'deactivated' : 'active' }, entity.id),
            });
          }}
        >
          {entity?.status === 'active' ? <StopOutlined /> : <CheckCircleOutlined />}
        </Button>
      </Tooltip>
      {/* <Tooltip title="Delete"> */}
      {/*  <DeleteOutlined */}
      {/*    onClick={(e) => { */}
      {/*      e.preventDefault(); */}
      {/*      open({ */}
      {/*        icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />, */}
      {/*        title: 'Delete admin', */}
      {/*        content: `Are you sure you want to Delete this admin (${entity.firstName} ${entity.lastName})?`, */}
      {/*        cancelText: 'Cancel', */}
      {/*        okText: 'Delete', */}
      {/*        onOk: () => userDelete.fetch(entity.id), */}
      {/*      }); */}
      {/*    }} */}
      {/*  /> */}
      {/* </Tooltip> */}
    </>
  );

  return (
    <>
      <Table<UserRow>
        rowKey="id"
        selectAll
        search={false}
        headerTitle="Admins"
        columnsState={{ persistenceKey: 'pro-table-admins', persistenceType: 'localStorage' }}
        toolBarRender={isRoot ? toolBarRender : () => []}
        options={{
          reload: false,
          density: false,
        }}
        actions={isRoot ? actions : undefined}
        columns={columns}
        loading={users.loading}
        pagingData={usersData}
      />
      {contextHolder}
    </>
  );
}
