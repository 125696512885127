import { Row } from 'antd';
import { useShiftActionContext } from '../../../Pages/Shift/Create/Context';
import ViewItem from '../ViewItem';
import { capitalize } from '../../../../utils';
import styles from './index.module.scss';

export default function DomainRoleSummary() {
  const { fields, form } = useShiftActionContext();
  const summary = form?.getFieldsValue();
  const experiences = fields?.experiences
    ?.filter(({ value }) => summary?.experiences?.includes(value))
    .map(({ title }) => title);
  const documents = fields?.documents
    ?.filter(({ value }) => summary?.documents?.includes(value))
    .map(({ title }) => title);

  return (
    <div className={styles.wrapper}>
      <Row gutter={[16, 0]}>
        <ViewItem text="Domain*" value={fields?.domains?.find(({ value }) => value === summary?.domain)?.label} />
        <ViewItem text="Location*" value={fields?.locations?.find(({ value }) => value === summary?.location)?.label} />
      </Row>
      <Row gutter={[16, 0]}>
        <ViewItem text="Role*" value={fields?.roles?.find(({ value }) => value === summary?.locationRole)?.label} />
        <ViewItem text="Rate Type*" value={capitalize(summary?.rateType)} />
        {summary?.rateType === 'no rate' ? null : (
          <ViewItem
            text="Amount*"
            value={summary?.rate ? `$${summary?.rate}` : `$${summary?.rateRangeFrom} - $${summary?.rateRangeTo}`}
          />
        )}
      </Row>
      <Row gutter={[16, 0]}>
        <ViewItem text="Experience*" value={experiences} />
        <ViewItem text="Minimum Required Documents" value={['Resume', ...(documents || [])]} />
      </Row>
      {summary?.additionalRequirements && (
        <Row gutter={[16, 0]}>
          <ViewItem text="Additional notes for assistants" value={summary.additionalRequirements} />
        </Row>
      )}
    </div>
  );
}
