import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.REACT_APP_ENV === 'local',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          email: 'Email',
          password: 'Password',
          name: 'Name',
          userName: 'User name',
          firstName: 'First name',
          lastName: 'Last name',
          surname: 'Surname',
          phone: 'Phone',
          contactPhone: 'Phone Number',
          repeatPassword: 'Repeat password',

          signIn: {
            google: 'Sign in with google',
            facebook: 'Sign in with facebook',
            apple: 'Sign in with apple',
          },

          signUp: {
            google: 'Sign up with google',
            facebook: 'Sign up with facebook',
            apple: 'Sign up with apple',
          },

          shiftDays: `Select the start and end date of this shift.
            Shifts can begin and end of the same day or stretch across multiple days.`,

          userRole: `Select a role for this user. Managers are assigned to specific locations.
            Client Admins can see all locations. Both Managers and Client Admins can manage users and create shifts`,

          shiftHours: 'Specify the hours for this shift.',

          shiftOnCall: 'Set a separate start and end time specifically for on-call requirements.',

          shiftNotes: 'This note will not be visible to assistants.',

          shiftDurationFirst: 'Creating one shift for each day allows different assistants to fill each shift.',
          shiftDurationSecond: 'A multi-day/swing shift only allows one assistant to be assigned to the entire shift.',

          shiftType: `With 'Instant Approval' shifts, the first qualified candidate to apply will
            be assigned the shift.
            A 'Client Review' shift means that multiple assistants can apply and the client
            will select one of their choosing.`,

          shiftInductions: `Select any inductions that will need to be completed by the successful assistant.
          Qualified assistants missing necessary inductions can still be assigned to shifts,
          however the shift status will change to 'Induction Required' as a reminder these will need to be completed.`,

          shiftPreferred: `Give your preferred assistants 24, 48, or 72 hours to apply for a shift before others can
           apply. The shift status will change to 'filled' when you select a successful assistant.".`,

          allowanceType: `Default allowance types are Flights, Meals, and Parking.
           You can also create your own.`,

          allowanceAmount: 'The Total Amount shows the dollar amount of each allowance type for the entire shift.',

          orientationDocuments: 'These orientation documents were provided by the client.',

          requiredDocuments: 'Please upload all required documents before starting this shift.',
        },
      },
    },
  });

export default i18n;
