import React, {
  createContext, useState, useEffect, PropsWithChildren,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { App } from 'antd';
import { AnyObject } from '@triare/auth-redux';
import { Applicant } from '../../../../../types/applicant';
import { useApplicantId, useUpdateApplicant } from '../../../../../hooks/applicant';
import { useAuthState } from '../../../../../store/auth';
import { UserRoles, isRoleEnough } from '../../../../../enums/user';
import { useMessageError } from '../../../../../hooks/common';
import { paramsToString } from '../../../../Layout/Menu';
import { getRouteParams } from '../../../../../routes';

type SettingsContextProps = {
  isEdit: boolean;
  setEdit: (callback: () => boolean) => void;
  isChanging: boolean;
  setChanging: (callback: () => boolean) => void;
  activeTab: string;
  setActiveTab: (callback: () => string) => void;
  onSave: (() => void) | undefined;
  setOnSave: (onSave: (attachData?: AnyObject) => void) => void;
  applicant: Applicant | null;
  fetch: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  applicantId: string | undefined;
  reviewed: {
    personalInfoNotes: boolean;
    interviewNotes: boolean;
  };
  handleReviewed: (name: string, checked: boolean) => void;
  handleVerification: (action: string, reason?: string) => void;
  isAdmin: boolean;
  isVerified: boolean;
  saveModalOpen: boolean;
  setSaveModalOpen: (saveModalOpen: boolean) => void;
};

const defaultValue: SettingsContextProps = {
  isEdit: true,
  setEdit: () => {
    // default
  },

  isChanging: false,
  setChanging: () => {
    // default
  },

  activeTab: '',
  setActiveTab: () => {
    // default
  },

  onSave: () => {
    // default
  },
  setOnSave: () => {
    // default
  },
  applicant: null,
  fetch: () => {
    // default
  },

  loading: false,
  setLoading: () => {
    // default
  },

  applicantId: '',

  reviewed: { personalInfoNotes: false, interviewNotes: false },
  handleReviewed: () => {
    // default
  },
  handleVerification: () => {
    // default
  },
  isAdmin: false,
  isVerified: false,

  saveModalOpen: true,
  setSaveModalOpen: () => {
    // default
  },
};

export const SettingsContext = createContext<SettingsContextProps>(defaultValue);

function SettingsProvider({ children }: PropsWithChildren) {
  const updateApplicant = useUpdateApplicant();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { user } = useAuthState();
  const { applicantId: paramsApplicantId } = useParams();
  const applicantId = user?.applicant?.id || paramsApplicantId;
  const getApplicant = useApplicantId();
  const [isChanging, setChanging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(true);
  const [onSave, setOnSave] = useState<((attachData?: AnyObject) => void) | undefined>();
  const [reviewed, setReviewed] = useState({
    personalInfoNotes: false,
    interviewNotes: false,
  });
  const [activeTab, setActiveTab] = useState('');
  const [applicant, setApplicant] = useState<Applicant | null>(null);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);
  const isVerified = !!applicant?.isVerified;

  const fetch = () => {
    getApplicant.fetch(undefined, applicantId);
  };

  const handleReviewed = (name: string, checked: boolean) => {
    setReviewed({ ...reviewed, [name]: checked });
  };

  const handleVerification = (action: string, reason?: string) => {
    if (isChanging && onSave) {
      const attachData = {};

      if (action === 'approve') {
        Object.assign(attachData, { isVerified: true, user: { status: 'active' } });
      }

      if (action === 'reject') {
        Object.assign(attachData, { user: { status: 'rejected' }, personalInfoNotes: reason });
      }

      onSave(attachData);
    } else {
      if (action === 'approve') {
        updateApplicant.fetch({ isVerified: true, user: { status: 'active' } }, applicant?.id);
      }

      if (action === 'reject') {
        updateApplicant.fetch({ user: { status: 'rejected' }, personalInfoNotes: reason }, applicant?.id);
      }
    }

    setLoading(true);
    setChanging(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (updateApplicant.data) {
      if (updateApplicant.data.isVerified) {
        message.success('The assistant has been successfully verified');
      } else {
        message.info('The assistant was notified that their registration was unsuccessful');
      }

      setLoading(false);

      navigate(`/applicants${paramsToString(
        user,
        getRouteParams('applicants', user),
      )}`, { replace: true });
    }
  }, [updateApplicant.data]);

  useEffect(() => {
    if (getApplicant.data) {
      if (!getApplicant.data?.isGeneral) {
        navigate(`/applicants${paramsToString(
          user,
          getRouteParams('applicants', user),
        )}`);

        return;
      }
      setApplicant(getApplicant.data);
    }
  }, [getApplicant.data]);

  useMessageError([getApplicant, updateApplicant]);

  return (
    <SettingsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isEdit,
        setEdit,

        isChanging,
        setChanging,

        activeTab,
        setActiveTab,

        onSave,
        setOnSave,

        applicant,
        fetch,

        loading,
        setLoading,

        applicantId,

        reviewed,
        handleReviewed,
        handleVerification,

        isAdmin,
        isVerified,

        setSaveModalOpen,
        saveModalOpen,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsProvider;

export const useSettingsContext = (): SettingsContextProps => React.useContext(SettingsContext);
