import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FilterWrapper from '../../../Common/Filter/Wrapper';
import Checkboxes from '../../../Common/Filter/Checkboxes';
import FilterMenu from '../../../Common/Filter/Menu';
import Select from '../../../Common/Filter/Select';
import { useAuthState } from '../../../../store/auth';
import { UserRoles } from '../../../../enums/user';
import { useDomainAllGet, useDomainShiftsGet } from '../../../../hooks/domain';
import { useLocationsShiftsSelectGet } from '../../../../hooks/location';
import { useLoadingTimeout, useMessageError, useSelectData } from '../../../../hooks/common';
import { useSearchParams, useWatchSearchParam } from '../../../../hooks/useSearchParams';
import { useClientsSelectAllGet, useClientsSelectShiftGet } from '../../../../hooks/client';
import { useShiftStatusesAllGet, useShiftStatusesGet, useShiftTypesGet } from '../../../../hooks/shift';
import { useLocationsRolesSelectGet } from '../../../../hooks/locationsRoles';
import { Applicant } from '../../../../types/applicant';
import Radio from '../../../Common/Filter/Radio';
import { useApplicantsSelectShiftsGet } from '../../../../hooks/applicant';
import { getShiftStatus } from '../../../../enums/shift';
import { getValidSearchParams } from '../../../../utils';
import { actions, useFilterData } from '../../../../store/filter';

interface FiltersProps {
  disabled?: boolean;
}

const shifts = [
  { label: 'All', key: '_all' },
  { label: 'Applicable shifts', key: 'true' },
];

function Filter({ disabled }: FiltersProps) {
  const { user } = useAuthState();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterData = useFilterData();
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);

  const defaultLocationsGet = useLocationsShiftsSelectGet();
  const locationsGet = useLocationsShiftsSelectGet();
  const locations = useSelectData(locationsGet);
  const locationsParam = useWatchSearchParam('locations');

  const defaultDomainShiftsGet = useDomainAllGet();
  const domainShiftsGet = useDomainShiftsGet();
  const domains = useSelectData(domainShiftsGet);

  const defaultClientsSelectAllGet = useClientsSelectAllGet();
  const clientsSelectShiftGet = useClientsSelectShiftGet();
  const clientsShift = useSelectData(clientsSelectShiftGet);

  const defaultShiftStatusesGet = useShiftStatusesAllGet();
  const shiftStatusesGet = useShiftStatusesGet();
  const shiftStatuses = useSelectData(shiftStatusesGet, (d, i, result) => ({
    ...result,
    label: getShiftStatus(result.label),
  }));

  const defaultShiftTypesGet = useShiftTypesGet();
  const shiftTypesGet = useShiftTypesGet();
  const shiftTypes = useSelectData(shiftTypesGet);

  const defaultLocationsRolesGet = useLocationsRolesSelectGet();
  const locationsRolesGet = useLocationsRolesSelectGet();
  const locationsRoles = useSelectData(locationsRolesGet);

  const defaultApplicantsSelectGet = useApplicantsSelectShiftsGet();
  const applicantsSelectGet = useApplicantsSelectShiftsGet();
  const applicantsSelect = useSelectData<Applicant[]>(applicantsSelectGet, (data, id, item) => ({
    ...item,
    label: data[id].user?.fullName,
  }));

  const locationsLoading = useLoadingTimeout([defaultLocationsGet, locationsGet]);
  const domainShiftsLoading = useLoadingTimeout([defaultDomainShiftsGet, domainShiftsGet]);
  const clientsLoading = useLoadingTimeout([defaultClientsSelectAllGet, clientsSelectShiftGet]);
  const shiftStatusesLoading = useLoadingTimeout([defaultShiftStatusesGet, shiftStatusesGet]);
  const shiftTypesLoading = useLoadingTimeout([defaultShiftTypesGet, shiftTypesGet]);
  const locationsRolesLoading = useLoadingTimeout([defaultLocationsRolesGet, locationsRolesGet]);
  const applicantsLoading = useLoadingTimeout([defaultApplicantsSelectGet, applicantsSelectGet]);

  const isOpenShiftPage = pathname.includes('/open-shifts');
  const isCalendar = pathname.includes('/calendar');

  // eslint-disable-next-line max-len
  const loading = domainShiftsLoading || locationsLoading || clientsLoading || shiftStatusesLoading || shiftTypesLoading || locationsRolesLoading || applicantsLoading;

  useEffect(() => {
    dispatch(actions.changeFiltering(loading));
  }, [loading]);

  useMessageError([
    defaultLocationsGet,
    locationsGet,
    defaultDomainShiftsGet,
    domainShiftsGet,
    defaultClientsSelectAllGet,
    clientsSelectShiftGet,
    defaultShiftStatusesGet,
    shiftStatusesGet,
    defaultShiftTypesGet,
    shiftTypesGet,
    defaultLocationsRolesGet,
    locationsRolesGet,
    defaultApplicantsSelectGet,
    applicantsSelectGet,
  ]);

  useEffect(() => {
    if (locationsGet.data && isCalendar) {
      const clients = locationsGet.data.filter((item) => locationsParam.includes(item.id));

      if (clients.length) {
        setSearchParams({
          ...getValidSearchParams('*', searchParams),
          clients: clients.map((client) => client.client.id),
        });
      }
    }
  }, [locationsParam]);

  return (
    <FilterWrapper clearFilters disabled={disabled}>
      <FilterMenu>
        {user?.role === UserRoles.APPLICANT ? (
          <>
            <Select
              title="Surgeons"
              name="domains"
              items={domains}
              defaultHook={defaultDomainShiftsGet}
              loading={domainShiftsLoading}
              fetch={domainShiftsGet.fetch}
              isMine={!isOpenShiftPage && user?.role === UserRoles.APPLICANT}
            />
            <Checkboxes
              title="Client"
              name="clients"
              items={clientsShift}
              defaultHook={defaultClientsSelectAllGet}
              loading={clientsLoading}
              fetch={clientsSelectShiftGet.fetch}
              isMine={!isOpenShiftPage && user?.role === UserRoles.APPLICANT}
              uncheck={isCalendar}
              search
              badge
              checkAll
            />
            <Checkboxes
              title="Locations"
              name="locations"
              items={locations}
              defaultHook={defaultLocationsGet}
              loading={locationsLoading}
              fetch={locationsGet.fetch}
              checkAll
              search
              keyAsArray
              isMine={!isOpenShiftPage && user?.role === UserRoles.APPLICANT}
            />
            <Checkboxes
              title="Status"
              name="statuses"
              items={shiftStatuses}
              decorateData={(d, i, result) => ({
                ...result,
                label: getShiftStatus(result.label),
              })}
              defaultHook={defaultShiftStatusesGet}
              loading={shiftStatusesLoading}
              fetch={shiftStatusesGet.fetch}
              isMine={!isOpenShiftPage && user?.role === UserRoles.APPLICANT}
              defaultCheck={false}
              badge
            />
            {isOpenShiftPage && <Radio title="Shifts" name="isApplicable" items={shifts} />}
            <Checkboxes
              title="Shift type"
              name="types"
              items={shiftTypes}
              defaultHook={defaultShiftTypesGet}
              loading={shiftTypesLoading}
              fetch={shiftTypesGet.fetch}
              isMine={!isOpenShiftPage && user?.role === UserRoles.APPLICANT}
            />
          </>
        ) : null}

        {user?.role === UserRoles.MANAGER || user?.role === UserRoles.CLIENT_ADMIN ? (
          <>
            <Select
              title="Surgeons"
              name="domains"
              items={domains}
              defaultHook={defaultDomainShiftsGet}
              loading={domainShiftsLoading}
              fetch={domainShiftsGet.fetch}
            />
            <Checkboxes
              title="Locations"
              name="locations"
              items={locations}
              defaultHook={defaultLocationsGet}
              loading={locationsLoading}
              fetch={locationsGet.fetch}
              checkAll
              search
              keyAsArray
            />
            <Checkboxes
              title="Status"
              name="statuses"
              items={shiftStatuses}
              decorateData={(d, i, result) => ({
                ...result,
                label: getShiftStatus(result.label),
              })}
              defaultHook={defaultShiftStatusesGet}
              loading={shiftStatusesLoading}
              fetch={shiftStatusesGet.fetch}
              checkAll
              badge
            />
            <Checkboxes
              title="Role"
              name="roles"
              items={locationsRoles}
              defaultHook={defaultLocationsRolesGet}
              loading={locationsRolesLoading}
              fetch={locationsRolesGet.fetch}
              checkAll
              search
              keyAsArray
            />
            <Checkboxes
              title="Assistants"
              name="applicants"
              items={applicantsSelect}
              decorateData={(data, id, item) => ({
                ...item,
                label: data[id].user?.fullName,
              })}
              defaultHook={defaultApplicantsSelectGet}
              loading={applicantsLoading}
              fetch={applicantsSelectGet.fetch}
              uncheck
              checkAll
              search
            />
          </>
        ) : null}

        {user?.role === UserRoles.LOKEM_ADMIN || user?.role === UserRoles.ROOT ? (
          <>
            <Select
              title="Surgeons"
              name="domains"
              items={domains}
              defaultHook={defaultDomainShiftsGet}
              loading={domainShiftsLoading}
              fetch={domainShiftsGet.fetch}
            />
            <Checkboxes
              title="Client"
              name="clients"
              items={clientsShift}
              defaultHook={defaultClientsSelectAllGet}
              loading={clientsLoading}
              fetch={clientsSelectShiftGet.fetch}
              uncheck={isCalendar}
              checkAll
              search
            />
            <Checkboxes
              title="Locations"
              name="locations"
              items={locations}
              defaultHook={defaultLocationsGet}
              loading={locationsLoading}
              fetch={locationsGet.fetch}
              checkAll
              search
              keyAsArray
            />
            <Checkboxes
              title="Shift status"
              name="statuses"
              items={shiftStatuses}
              decorateData={(d, i, result) => ({
                ...result,
                label: getShiftStatus(result.label),
              })}
              defaultHook={defaultShiftStatusesGet}
              loading={shiftStatusesLoading}
              fetch={shiftStatusesGet.fetch}
              checkAll
              badge
            />
            <Checkboxes
              title="Role"
              name="roles"
              items={locationsRoles}
              defaultHook={defaultLocationsRolesGet}
              loading={locationsRolesLoading}
              fetch={locationsRolesGet.fetch}
              checkAll
              search
              keyAsArray
            />
            <Checkboxes
              title="Assistants"
              name="applicants"
              items={applicantsSelect}
              decorateData={(data, id, item) => ({
                ...item,
                label: data[id].user?.fullName,
              })}
              defaultHook={defaultApplicantsSelectGet}
              loading={applicantsLoading}
              fetch={applicantsSelectGet.fetch}
              checkAll
              uncheck
              search
            />
          </>
        ) : null}
      </FilterMenu>
    </FilterWrapper>
  );
}

Filter.defaultProps = {
  disabled: false,
};

export default Filter;
