import React from 'react';
import Layout from '../../../components/Layout';
import ContentApplicantList from '../../../components/Pages/Applicant/List';
import Filter from '../../../components/Pages/Applicant/Filters';

export default function ApplicantList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Assistant list`;

  return (
    <Layout minWidth leftMenu={<Filter />}>
      <ContentApplicantList />
    </Layout>
  );
}
