import { useState, useEffect } from 'react';
import {
  Button, Input, Modal, Form, App,
} from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { useRequestInfo } from '../../../../../hooks/applicant';
import { useSettingsContext } from '../Context';
import { useMessageError } from '../../../../../hooks/common';

import styles from './index.module.scss';

export default function MoreInfo() {
  const { message } = App.useApp();
  const [isValid, setValid] = useState(false);
  const [form] = Form.useForm();
  const [isModalOpen, setModalOpen] = useState(false);
  const requestInfo = useRequestInfo();
  const { applicantId } = useSettingsContext();

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    if (allFields.every((field) => field?.errors?.length === 0)) {
      setValid(() => true);
    } else {
      setValid(() => false);
    }
  };

  useEffect(() => {
    if (requestInfo.data) {
      message.success('You have successfully submitted a request for more information');
      form.setFieldValue('reason', '');
      setModalOpen(false);
    }
  }, [requestInfo.data]);

  useMessageError([requestInfo]);

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Request more info</Button>
      <Modal
        title="Request more info"
        open={isModalOpen}
        onOk={() => {
          requestInfo.fetch(
            { text: `${form.getFieldValue('reason')} Please email your response to support@lokem.com.au` },
            `${applicantId}/request-info`,
          );
        }}
        onCancel={() => setModalOpen(false)}
        okButtonProps={{ disabled: !isValid, loading: requestInfo.loading }}
        okText="Send"
        className={styles.modal}
        closable={false}
        centered
      >
        <Form form={form} requiredMark={false} layout="vertical" onFieldsChange={onFieldsChange}>
          <Form.Item name="reason" rules={[{ required: true, message: 'Should be at least one character long!' }]}>
            <Input.TextArea
              // eslint-disable-next-line max-len
              placeholder="Please enter what additional information you would like from this assistant. They will receive your request via email."
              rows={4}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
