import { DeleteOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useMemo } from 'react';
import { Button, Alert, Typography } from 'antd';
import { useSearchParams, useNavigate } from 'react-router-dom';
import type { ProColumns } from '@ant-design/pro-components';
import { PagingDataResponse } from '../../../../hooks/fetch';
import Table from '../../../Common/Table';
import { useShiftGet, Shift } from '../../../../hooks/shift';
import { getValidSearchParams, capitalize } from '../../../../utils';
import {
  ShiftItem, shiftAllowances, shiftRate, shiftRole, shiftTimeColumn,
} from '../List';
import TopPanel from '../../../Common/TopPanel';
import Apply from '../../../Common/Shift/Apply';
import { useApplicantId } from '../../../../hooks/applicant';
import { useAuthState } from '../../../../store/auth';

import styles from './index.module.scss';

const columns: ProColumns<ShiftItem>[] = [
  shiftTimeColumn,
  shiftRole,
  {
    title: 'Shift type',
    dataIndex: 'type',
    key: 'type',
    render: (_, record) => capitalize(record?.shiftType),
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: (_, record) => record?.location?.name,
  },
  shiftRate,
  shiftAllowances,
];

function Confirmation(): React.ReactNode {
  const shiftGet = useShiftGet();
  const { user } = useAuthState();
  const applicantId = user?.applicant?.id;
  const applicant = useApplicantId();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeShift, setActiveShift] = useState<Shift | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    applicant.fetch(undefined, applicantId);
  }, []);

  const getShifts = (id?: string) => {
    if (id) {
      deleteItem(id);
    }

    if (searchParams.getAll('shifts').length) {
      shiftGet.fetch({
        ...getValidSearchParams('*', searchParams),
      });
      setActiveShift(null);
    } else {
      navigate('/open-shifts');
    }
  };

  useEffect(getShifts, [searchParams]);

  const shiftsData = useMemo<PagingDataResponse<ShiftItem>>(() => {
    if (shiftGet.data && shiftGet.data.data && shiftGet.data.data.length) {
      return {
        ...shiftGet.data,
        data: shiftGet.data.data.map((items) => ({
          ...items,
          urlPrefix: '/open-shifts/',
        })),
      } as PagingDataResponse<ShiftItem>;
    }

    return shiftGet.data as PagingDataResponse<ShiftItem>;
  }, [shiftGet.data?.data]);

  const rowSelection = {
    columnTitle: ' ',
    onChange: (selectedRowKeys: React.Key[], selectedRows: ShiftItem[]) => {
      if (selectedRows) {
        setActiveShift(selectedRows[0]);
      }
    },
  };

  const columnsWithAction: ProColumns<ShiftItem>[] = [
    ...columns,
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          type="text"
          onClick={() => {
            deleteItem(record.id);
          }}
        >
          <DeleteOutlined style={{ color: '#8C8C8C' }} />
        </Button>
      ),
    },
  ];

  const deleteItem = (id: string) => {
    const urlParams = new URLSearchParams(window.location.search);

    setSearchParams({
      shifts: urlParams.getAll('shifts').filter((item) => item !== id),
    });
  };

  return (
    <div className={styles.wrapper}>
      <TopPanel
        padding
        arrowBack="/open-shifts"
        title="Shift confirmation"
        rightContent={
          activeShift ? (
            <Apply
              shift={activeShift}
              callback={getShifts}
              loading={applicant.loading}
              disabledBtn={
                // eslint-disable-next-line no-nested-ternary
                activeShift?.experiences?.some((item) => {
                  const experiencesIds = applicant?.data?.experiences?.map(({ id }) => id);

                  return experiencesIds?.includes(item.id);
                })
                  ? false || activeShift.applicants.length >= activeShift.maxApplicantAmount
                    ? 'The shift has reached the max number of assistants.'
                    : false
                  : 'Your experience does not meet the requirements for this shift.'
              }
              textBtn="Apply for selected shift"
            />
          ) : null
        }
      />
      <Alert
        message={(
          <span>
            If the shift(s) you have selected are &quot;instant&quot; shifts, you will be confirmed immediately. Shifts
            with &quot;Client Review&quot; status require manual confirmation by the client. Please be sure you can
            fulfill the requirements of this shift and review any additional notes for assistants listed below.
            You will be contacted prior to the start date if any shift(s) you have selected requires an induction.
          </span>
        )}
        type="warning"
        showIcon
        className={styles.alert}
      />
      <div className={styles.tableWrapper}>
        <Table<ShiftItem>
          rowKey="id"
          columnsState={{ persistenceKey: 'pro-table-confirmation', persistenceType: 'localStorage' }}
          search={false}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          options={{
            reload: false,
            density: false,
            setting: false,
          }}
          columns={columnsWithAction}
          loading={shiftGet.loading}
          pagingData={shiftsData}
          tableAlertRender={false}
        />

        {activeShift?.additionalRequirements && (
          <div className={styles.text}>
            <Typography.Text strong>Additional notes for assistants for selected shift</Typography.Text>
            <div className={styles.notes}>
              Please ensure you meet the additional notes for assistants outlined below.
              If documents are requested, make sure to upload them as soon as possible to your profile.
            </div>
            <Typography.Text className={styles.requirements}>{activeShift?.additionalRequirements}</Typography.Text>
          </div>
        )}
      </div>
    </div>
  );
}

export default Confirmation;
