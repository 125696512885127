import { useEffect } from 'react';
import { Badge, Tooltip, Space } from 'antd';
import { NavLink } from 'react-router-dom';
import { EyeTwoTone } from '@ant-design/icons';
import type { ProColumns } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import { useApplicantsGet, ApplicantsGetParams, applicantsGetParamList } from '../../../../hooks/applicant';
import { Applicant, Status } from '../../../../types/applicant';
import { useAuthState } from '../../../../store/auth';
import Table from '../../../Common/Table';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { UserRoles, isRoleEnough } from '../../../../enums/user';
import { getBudgeColor } from '../../../Common/Badge';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';
import RadioSwitch from '../../Guest/RadioSwitch';

import styles from './index.module.scss';

export const nameColumn: ProColumns<Applicant> = {
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
  render: (_, record) => (
    <NavLink to={record.id}>
      {record.user.firstName}
      {' '}
      {record.user.lastName}
    </NavLink>
  ),
};
export const experienceColumn: ProColumns<Applicant> = {
  title: 'Experience',
  dataIndex: 'experience',
  width: '23%',
  key: 'experience',
  render: (_, record) => (record.experiences.length ? (
    <Tooltip
      className={styles.tooltipe}
      overlayClassName={styles.tooltipe}
      title={(
        <Space direction="vertical">
          {record.experiences.map(({ title, id }) => (
            <Badge key={id} className={styles.bullet} color="#fff" text={title} />
          ))}
        </Space>
        )}
    >
      {`${record.experiences.length}  ${record.experiences.length > 1 ? 'experience types' : 'experience type'}  `}
      <EyeTwoTone twoToneColor={COLOR_PRIMARY} style={{ cursor: 'pointer' }} />
    </Tooltip>
  ) : (
    'Not set'
  )),
};

export const teamColumn: ProColumns<Applicant> = {
  title: 'Team(s)',
  dataIndex: 'teams',
  width: '23%',
  key: 'teams',
  render: (_, record) => (record.teams.length ? (
    <Tooltip
      className={styles.tooltipe}
      overlayClassName={styles.tooltipe}
      title={(
        <Space direction="vertical">
          {record.teams.map(({ name, id }) => (
            <Badge key={id} className={styles.bullet} color="#fff" text={name} />
          ))}
        </Space>
        )}
    >
      {`${record.teams.length}  ${record.teams.length > 1 ? 'teams' : 'team'}  `}
      <EyeTwoTone twoToneColor={COLOR_PRIMARY} style={{ cursor: 'pointer' }} />
    </Tooltip>
  ) : (
    'Not set'
  )),
};
export const employmentTypeColumn: ProColumns<Applicant> = {
  title: 'Employment type',
  dataIndex: 'employmentType',
  key: 'employmentType',
  render: (type) => <span className={styles.status}>{type}</span>,
};
export const hourlyRateColumn: ProColumns<Applicant> = {
  title: 'Hourly rate',
  dataIndex: 'hourlyRate',
  key: 'hourlyRate',
  render: (rate) => (rate !== '-' ? `$${rate}` : rate),
};
export const lastShiftColumn: ProColumns<Applicant> = {
  title: 'Last Shift',
  dataIndex: 'last shift',
  key: 'lastShift',
  sorter: true,
  render: (_, record) => (record?.lastShift ? (
    <span>{`${dayjs(record?.lastShift?.datetimeEnd)?.utc()?.format('dddd, MMM D: HH:mm')}`}</span>
  ) : (
    'none'
  )),
};
export const completedShiftsColumn: ProColumns<Applicant> = {
  title: 'Completed Shifts',
  dataIndex: 'completed shifts',
  key: 'completed shifts',
  sorter: true,
  render: (_, record) => (record.shiftCount ? record.shiftCount : 0),
};

export default function ApplicantList() {
  const { user } = useAuthState();
  const clientId = user?.locationAdmin?.client.id || user?.manager?.client.id;
  const applicantsGet = useApplicantsGet();
  const [searchParams] = useSearchParams();
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);

  const getStatus = (statuses: Status[], userStatus: string) => {
    const applicantStatus = clientId ? statuses.find((status) => status.client.id === clientId) : false;

    if (applicantStatus) {
      return applicantStatus.status;
    }

    return userStatus || '';
  };

  const columns: ProColumns<Applicant>[] = [
    nameColumn,
    experienceColumn,
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => (
        <Badge
          className={styles.status}
          status="success"
          color={getBudgeColor(getStatus(record.statuses, record.user.status))}
          text={getStatus(record.statuses, record.user.status)}
        />
      ),
    },
    employmentTypeColumn,
    hourlyRateColumn,
  ];

  const clientColumns: ProColumns<Applicant>[] = [...columns, lastShiftColumn, completedShiftsColumn];

  const adminColumns: ProColumns<Applicant>[] = [
    ...columns,
    teamColumn,
    lastShiftColumn,
    {
      title: 'Last Shift location',
      dataIndex: 'last shift',
      key: 'last shift',
      sorter: true,
      render: (_, record) => record?.lastShift?.location?.name || '-',
    },
    completedShiftsColumn,
  ];

  useEffect(() => {
    const props: ApplicantsGetParams = {};

    applicantsGetParamList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // @ts-ignore @typescript-eslint/ban-ts-comment
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    if (clientId) {
      props.clients = clientId;
    }

    applicantsGet.fetch({ ...props, isGeneral: true });
  }, [searchParams]);

  return (
    <Table
      rowKey="id"
      search={false}
      headerTitle={(
        <Space>
          Assistants
          {isAdmin && <RadioSwitch defaultActive="applicants" />}
        </Space>
      )}
      columnsState={{ persistenceKey: 'pro-table-applicants', persistenceType: 'localStorage' }}
      options={{
        reload: false,
        density: false,
      }}
      columns={isAdmin ? adminColumns : clientColumns}
      loading={applicantsGet.loading}
      pagingData={applicantsGet.data}
    />
  );
}
