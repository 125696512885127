import { useState, useEffect } from 'react';
import {
  Typography, Modal, Select, App,
} from 'antd';
import { useDomainsAllGet } from '../../../../../hooks/domain';
import { Client, useClientUpdate } from '../../../../../hooks/client';
import { useMessageError } from '../../../../../hooks/common';

import styles from './index.module.scss';

type DomainModalProps = {
  domainModal: boolean;
  setDomainModal: React.Dispatch<React.SetStateAction<boolean>>;
  fetch: (s: string) => void;
  client: Client;
};

type Items = {
  label: string;
  value: string;
};

export default function DomainModal({
  domainModal, setDomainModal, fetch, client,
}: DomainModalProps) {
  const { message } = App.useApp();
  const updateClient = useClientUpdate();
  const domainsAllGet = useDomainsAllGet();
  const [domains, setDomains] = useState<Items[] | null>(null);
  const [domain, setDomain] = useState<string | null>(null);

  const handleOk = () => {
    if (domain) {
      const params = {
        domains: [...client.domains.map((item) => item.id), domain],
      };

      updateClient.fetch(params, client.id);
    }
  };

  useEffect(() => {
    if (domainModal) {
      domainsAllGet.fetch();
    }
  }, [domainModal]);

  useEffect(() => {
    if (domainsAllGet.data) {
      const clientDomains = client?.domains?.map((item) => item.id);

      setDomains(
        domainsAllGet.data
          .map((item) => ({ label: item.title, value: item.id }))
          .filter((item) => !clientDomains?.includes(item.value)),
      );
    }
  }, [domainsAllGet.data]);

  useEffect(() => {
    if (updateClient.data) {
      setDomainModal(false);
      setDomain(null);
      fetch('fetch');
      message.info('You have successfully added a new surgeon');
    }
  }, [updateClient.data]);

  useMessageError([updateClient, domainsAllGet]);

  const handleChange = (value: string) => {
    setDomain(value);
  };

  return (
    <Modal
      title="Add new surgeon"
      open={domainModal}
      onOk={handleOk}
      okButtonProps={{ disabled: !domain, loading: updateClient.loading }}
      onCancel={() => setDomainModal(false)}
      okText="Save"
      className={styles.modal}
      closable={false}
    >
      <Typography.Text strong>Surgeon name</Typography.Text>
      <Select
        className={styles.select}
        placeholder="Please select"
        value={domain}
        onChange={handleChange}
        options={domains || []}
      />
    </Modal>
  );
}
