import {
  Modal as AntdModal, Button, Table, Badge, Space, Tooltip, Input, App,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { NavLink } from 'react-router-dom';
import { EyeTwoTone } from '@ant-design/icons';
import { debounce } from 'lodash';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';
import { Applicant } from '../../../../../types/applicant';
import { ApplicantsGetParams, useApplicantsGet } from '../../../../../hooks/applicant';
import { useMessageError } from '../../../../../hooks/common';
import { useRosterContext } from '../../Context';
import styles from './index.module.scss';
import { useShiftUpdate } from '../../../../../hooks/shift';
import ShiftStatus from '../../../../../enums/shift';

const columns: ColumnsType<Applicant> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <NavLink target="_blank" to={`/applicants/${record.id}`}>
        {record.title}
        {' '}
        {record.user.fullName}
      </NavLink>
    ),
  },
  {
    title: 'Experience',
    dataIndex: 'experience',
    width: '23%',
    key: 'experience',
    render: (_, record) => (record.experiences.length ? (
      <Tooltip
        className={styles.tooltipe}
        overlayClassName={styles.tooltipe}
        title={(
          <Space direction="vertical">
            {record.experiences.map(({ title, id }) => (
              <Badge key={id} className={styles.bullet} color="#fff" text={title} />
            ))}
          </Space>
          )}
      >
        {`${record.experiences.length}  ${record.experiences.length > 1 ? 'experience types' : 'experience type'}  `}
        <EyeTwoTone twoToneColor={COLOR_PRIMARY} style={{ cursor: 'pointer' }} />
      </Tooltip>
    ) : (
      'Not set'
    )),
  },
  {
    title: 'Phone number',
    dataIndex: 'number',
    render: (_, record) => record.user.phone,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    render: (_, record) => record.user.email,
  },
];

export default function AssignModal() {
  const {
    assignModal, setAssignModal, getShifts, setSelectedRef,
  } = useRosterContext();
  const applicantsGet = useApplicantsGet();
  const updateShift = useShiftUpdate();
  const { message } = App.useApp();
  const [assigns, setAssigns] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [applicants, setApplicants] = useState<Applicant[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (applicantsGet.data) {
      setApplicants(page === 1 ? applicantsGet.data.data : [...applicants, ...applicantsGet.data.data]);
    }
  }, [applicantsGet.data]);

  useEffect(() => {
    if (assignModal) {
      const props: ApplicantsGetParams = {
        isGeneral: true,
        page,
        pageSize: 11,
        userStatuses: ['active'],
        isVerified: true,
        from: assignModal.datetimeStart,
        to: assignModal.datetimeEnd,
      };

      if (search) {
        props.partialFullName = search;
      }

      applicantsGet.fetch(props);
    }
  }, [page, search, assignModal]);

  const handleSearch = useMemo(
    () => debounce((value: string) => {
      setPage(1);
      setSearch(value);
    }, 300),
    [],
  );

  useEffect(() => {
    if (updateShift.data) {
      message.success('The assistant has been successfully assigned');
      getShifts();
      setAssignModal(null);
      setSelectedRef(undefined);
    }
  }, [updateShift.data]);

  useMessageError([applicantsGet, updateShift]);

  return (
    <div className={styles.wrapper}>
      <AntdModal
        className={styles.modal}
        title="Assign assistant"
        open={!!assignModal}
        onOk={() => {
          const params = {
            applicant: assigns,
            status: assignModal?.status === ShiftStatus.DRAFT ? ShiftStatus.DRAFT : assignModal?.status,
          };

          updateShift.fetch(params, assignModal?.id);
        }}
        onCancel={() => setAssignModal(null)}
        okText="Assign assistant"
        okButtonProps={{ disabled: !assigns?.length, loading: updateShift.loading }}
      >
        <Input.Search className={styles.search} placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
        <Table
          rowSelection={{
            type: 'radio',
            onChange: (keys: React.Key[], rows: Applicant[]) => setAssigns(rows.length ? rows[0].id : null),
          }}
          columns={columns}
          dataSource={applicants}
          rowKey="id"
          loading={applicantsGet.loading}
          className={styles.table}
          pagination={{
            pageSize: 10000,
          }}
        />
        {applicantsGet?.data?.meta.hasNextPage && (
          <Button
            className={styles.showMore}
            type="link"
            onClick={() => setPage(page + 1)}
            loading={applicantsGet.loading}
          >
            Show more
          </Button>
        )}
      </AntdModal>
    </div>
  );
}
