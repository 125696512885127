import { App, Badge, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridShift, useRosterContext } from '../../Context';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';
import { getHoursBetween } from '../../../../../utils';
import { useShiftDelete, useShiftPublish, useShiftUpdate } from '../../../../../hooks/shift';
import { useMessageError } from '../../../../../hooks/common';
import { Action } from '../../../../../enums/roster';
import ShiftStatus, { adminNotEditStatuses, editStatuses } from '../../../../../enums/shift';

interface OptionsContentProps {
  setShowModal: (value: boolean) => void;
}

export default function OptionsContent({ setShowModal }: OptionsContentProps) {
  const [updateType, setUpdateType] = useState<string>('');
  const updateShift = useShiftUpdate();
  const deleteShift = useShiftDelete();
  const publishShifts = useShiftPublish();
  const navigate = useNavigate();
  const { open, contextHolder } = useSimpleModal();
  const { message } = App.useApp();
  const {
    selectedRef, setAssignModal, getShifts, setSelectedRef, setSelectedShift,
  } = useRosterContext();
  const shift = selectedRef?.shift || null;

  useEffect(() => {
    if (updateShift.data) {
      // eslint-disable-next-line default-case
      switch (updateType) {
        case 'unassign':
          message.success('Shift has been unassigned successfully and added to the open shifts list');
          break;

        case 'unpublish':
          message.success('Shift unpublished');
          break;
      }
      getShifts();
      setSelectedRef(undefined);
    }
  }, [updateShift.data]);

  useEffect(() => {
    if (deleteShift.data) {
      message.info('Shift has been deleted successfully');
      getShifts();
      setSelectedRef(undefined);
    }
  }, [deleteShift.data]);

  useEffect(() => {
    if (publishShifts.data) {
      message.info('Shift has been published');
      getShifts();
      setSelectedRef(undefined);
    }
  }, [publishShifts.data]);

  useMessageError([updateShift, deleteShift, publishShifts]);

  if (!shift) {
    return null;
  }

  return (
    <>
      <Button block type="text" onClick={() => setShowModal(true)}>
        Shift details
      </Button>
      {!adminNotEditStatuses.includes(shift.status as ShiftStatus) && (
        <Button
          block
          type="text"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/roster/${shift?.id}/edit`);
          }}
        >
          Edit shift
        </Button>
      )}
      <Button
        block
        type="text"
        onClick={() => {
          setSelectedShift({
            ...shift,
            action: Action.COPY,
          } as GridShift);
          setSelectedRef(undefined);
        }}
      >
        Copy shift
      </Button>
      {shift.status !== ShiftStatus.DELETE && (
        <Button
          block
          type="text"
          onClick={() => {
            open({
              title: 'Delete shift',
              content: 'Are you sure that you want to delete this shift?',
              cancelText: 'Cancel',
              okText: 'Delete',
              centered: true,
              onOk: () => deleteShift.fetch(shift?.id),
            });
          }}
        >
          Delete shift
        </Button>
      )}
      {shift && shift.id && shift.status === ShiftStatus.DRAFT ? (
        <Button
          loading={publishShifts.loading}
          block
          type="text"
          onClick={() => publishShifts.fetch({
            ids: [shift.id],
          })}
        >
          Publish shift
        </Button>
      ) : null}

      {shift && shift.id && shift.status !== ShiftStatus.DRAFT ? (
        <Button
          loading={updateType === 'unpublish' && updateShift.loading}
          block
          type="text"
          onClick={() => {
            open({
              title: 'Unpublish shift',
              content: (
                <>
                  Are you sure that you want to unpublish shift?
                  <Badge
                    color={COLOR_PRIMARY}
                    text={`${getHoursBetween(shift.datetimeStart, shift.datetimeEnd)} | ${shift.client.name} | ${
                      shift.locationRole.name
                    }`}
                  />
                </>
              ),
              cancelText: 'Cancel',
              okText: 'Unpublish',
              centered: true,
              onOk: () => {
                setUpdateType('unpublish');
                updateShift.fetch({ status: ShiftStatus.DRAFT }, shift.id);
              },
            });
          }}
        >
          Unpublish shift
        </Button>
      ) : null}

      {shift && shift.applicant ? (
        <Button
          loading={updateType === 'unassign' && updateShift.loading}
          block
          type="text"
          onClick={() => {
            open({
              title: 'Unassign shift',
              content: (
                <>
                  Are you sure that you want to unassign shift and notify the assistant?
                  <Badge
                    color={COLOR_PRIMARY}
                    text={`${getHoursBetween(shift.datetimeStart, shift.datetimeEnd)} | ${shift.client.name} | ${
                      shift.locationRole.name
                    }`}
                  />
                </>
              ),
              cancelText: 'Cancel',
              okText: 'Unassign',
              centered: true,
              onOk: () => {
                setUpdateType('unassign');
                updateShift.fetch({ applicant: null }, shift.id);
              },
            });
          }}
        >
          Unassign shift
        </Button>
      ) : (
        <Button block type="text" onClick={() => setAssignModal(shift)}>
          Assign shift
        </Button>
      )}
      {contextHolder}
    </>
  );
}
