import { useEffect, useState, useRef } from 'react';
import {
  Space, Transfer, Typography, App,
} from 'antd';
import { AnyObject } from '@triare/auth-redux';
import InductionsList from '../../../../Common/Applicant/Inductions';
import { useSettingsContext } from '../Context';
import { useInductionsGet } from '../../../../../hooks/inductions';
import Notes from '../../../../Common/Applicant/Notes';
import { useUpdateApplicant } from '../../../../../hooks/applicant';
import { useMessageError } from '../../../../../hooks/common';

import styles from './index.module.scss';

export interface RecordType {
  key: string;
  title: string;
  description?: string;
  chosen?: boolean;
}

export default function Inductions() {
  const {
    setActiveTab, isAdmin, setEdit, setOnSave, isEdit, setChanging, fetch, setLoading, applicant,
  } = useSettingsContext();
  const inductions = useInductionsGet();
  const [targetKeys, setTargetKeys] = useState(['test']);
  const { message } = App.useApp();
  const targets = useRef<string[]>([]);
  const updateApplicant = useUpdateApplicant();

  useEffect(() => {
    setActiveTab(() => 'inductions');
    inductions.fetch();
    setEdit(() => true);
    setChanging(() => false);
    setOnSave(() => onSave);
  }, []);

  useEffect(() => {
    if (updateApplicant.data) {
      message.info('The assistant has been successfully updated');
      setChanging(() => false);
      fetch();
      setLoading(false);
    }
  }, [updateApplicant.data]);

  useEffect(() => {
    if (applicant?.inductions) {
      setTargetKeys(applicant.inductions.map((item) => item.id));
    }
    setEdit(() => true);
  }, [isEdit]);

  useMessageError([inductions, updateApplicant]);

  const onChange = (nextSourceKeys: string[]) => {
    setTargetKeys(nextSourceKeys);
    targets.current = nextSourceKeys;
    setChanging(() => true);
  };

  const onSave = (attachData: AnyObject = {}) => {
    setLoading(true);
    updateApplicant.fetch({ inductions: targets.current, ...attachData }, applicant?.id);
  };

  return isAdmin ? (
    <Space direction="vertical">
      <Notes name="inductionNotes" />
      <div className={styles.titles}>
        <Typography.Title level={5} className={styles.title}>
          Completed inductions
        </Typography.Title>
        <Typography.Title level={5} className={styles.title}>
          Available inductions
        </Typography.Title>
      </div>
      <Transfer<RecordType>
        className={styles.transfer}
        dataSource={inductions.data?.map((item) => ({ title: item.title, key: item.id })) || []}
        targetKeys={targetKeys}
        // @ts-ignore string[] as Key[]
        onChange={onChange}
        selectAllLabels={['All', 'All']}
        render={(item) => item.title}
      />
    </Space>
  ) : (
    applicant && <InductionsList checked={false} inductions={applicant.inductions} />
  );
}
