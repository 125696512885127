import Details from './Components/Details';
import Documents from './Components/Documents';
import TopBar from './Components/TopBar';
import Tabs from '../../Common/Tabs';
import Inductions from '../../Common/Applicant/Inductions';
import SettingsProvider, { useSettingsContext } from './Context';
import Loading from '../../Common/Loading';

import styles from './index.module.scss';

function Content() {
  const { loading, applicant } = useSettingsContext();

  return loading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.wrapper}>
      <TopBar />
      <Tabs
        destroyInactive={false}
        items={[
          {
            label: 'Assistant Details',
            key: 'applicant-details',
            children: <Details />,
          },
          {
            label: 'Documents',
            key: 'documents',
            children: <Documents />,
          },
          {
            label: 'Inductions',
            key: 'inductions',
            children: applicant && <Inductions inductions={applicant.inductions} />,
          },
        ]}
      />
    </div>
  );
}

export default function Applicant() {
  return (
    <SettingsProvider>
      <Content />
    </SettingsProvider>
  );
}
