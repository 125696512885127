import React from 'react';
import { useParams } from 'react-router-dom';
import SettingsProvider from './Context';
import { TabPaneItem } from '../../Common/Tabs';
import Experiences from './Experiences';
import Detail from './Common/Detail';
import Inductions from './Inductions';
import Documents from './Documents';
import Domains from './Domains';

export interface SettingsTabPaneItem extends TabPaneItem {
  url: string
}

export const tabList: SettingsTabPaneItem[] = [
  {
    label: 'Experience',
    key: 'experiences',
    url: '/settings',
    children: <Experiences />,
  },
  {
    label: 'Inductions',
    key: 'inductions',
    url: '/settings/inductions',
    children: <Inductions />,
  },
  {
    label: 'Documents',
    key: 'documents',
    url: '/settings/documents',
    children: <Documents />,
  },
  {
    label: 'Surgeons',
    key: 'surgeons',
    url: '/settings/surgeons',
    children: <Domains />,
  },
];

export default function Settings(): React.ReactNode | null {
  const { tab } = useParams();
  const activeTab = tabList.find(({ key }) => key === tab)?.key || tabList[0].key;

  return (
    <SettingsProvider defaultTab={activeTab}>
      <Detail
        arrowBack={false}
        tabList={tabList}
        name="Settings"
      />
    </SettingsProvider>
  );
}
