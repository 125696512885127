import React from 'react';
import Layout from '../../components/Layout';
import ContentTeam from '../../components/Pages/Team';

export default function Team(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Assistant`;

  return (
    <Layout disableLeftMenu>
      <ContentTeam />
    </Layout>
  );
}
