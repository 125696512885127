import { useEffect, useState } from 'react';
import {
  Col, Form, Row, Typography, Input, Button, App,
} from 'antd';
import { NavLink } from 'react-router-dom';
import { AnyObject } from '@triare/auth-redux';
import Wrapper from '../../../../Common/Applicant/Wrapper';
import { useSettingsContext } from '../Context';
import AHPRAnumber from '../../../../Common/Applicant/AHPRAnumber';
import Indemnity from '../../../../Common/Applicant/Indemnity';
import BankDetails from '../../../../Common/Applicant/BankDetails';
import { useUpdateApplicant, ApplicantsUpdateParams } from '../../../../../hooks/applicant';
import { Applicant } from '../../../../../types/applicant';
import { createRulesForAntd, validationApplicant } from '../../../../../utils/validations';
import General from '../../../../Common/Applicant/General';
import ChangePhone from '../../../../Common/ChangePhone';
import ChangeEmail from '../../../../Common/ChangeEmail';
import ChangePassword from '../../../../Common/ChangePassword/index';
import Reviewed from '../../../../Common/Applicant/Reviewed';
import Notes from '../../../../Common/Applicant/Notes';
import Interview from '../../../../Common/Applicant/Interview';
import { usePasswordForgot } from '../../../../../hooks/password';
import Experiences from '../../../../Common/Experiences';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';
import ReceiveEmails from '../../../../Common/ReceiveEmails';
import { UserStatus } from '../../../../../enums/user';
import { useMessageError } from '../../../../../hooks/common';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationApplicant);

export default function Details() {
  const passwordCreate = usePasswordForgot();
  const updateApplicant = useUpdateApplicant();
  const { message } = App.useApp();
  const {
    isEdit,
    setEdit,
    setActiveTab,
    setOnSave,
    setChanging,
    applicant,
    fetch,
    setLoading,
    applicantId,
    isAdmin,
    isVerified,
  } = useSettingsContext();
  const [phoneModalOpen, setPhoneModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [form] = Form.useForm();
  const fromValues = Form.useWatch([], form);
  const isPending = (isAdmin && applicant && applicant.user.status === UserStatus.PENDING) || false;

  useEffect(() => {
    setActiveTab(() => 'details');
    setEdit(() => true);
    setChanging(() => false);
    setOnSave(() => onSave);
  }, []);

  const onSave = (attachData: AnyObject = {}) => {
    const values = form.getFieldsValue();

    const applicantParams: ApplicantsUpdateParams = {
      title: values.title,
      experiences: values.experiences,
      ahpra: values?.ahpra,
      indemnityProvider: values?.indemnityProvider,
      indemnityNumber: values?.indemnityNumber,
      abn: values?.abn,
      bsb: values?.bsb,
      accountNumber: values?.accountNumber,
      user: {
        firstName: values?.firstName,
        lastName: values?.lastName,
        address: values?.address,
      },
      ...attachData,
    };

    const paramsForAdmin: ApplicantsUpdateParams = {
      ...applicantParams,
      interviewScheduled: values?.interviewScheduled,
      interviewSuccessful: values?.interviewSuccessful,
      user: {
        ...applicantParams?.user,
        phone: values?.phone,
        email: values?.email,
      },
    };

    form
      .validateFields()
      .then(() => {
        setLoading(true);
        updateApplicant.fetch(isAdmin ? paramsForAdmin : applicantParams, applicantId);
      })
      .catch(() => {
        setChanging(() => false);
      });
  };

  const onFieldsChange = (_: any, allFields: any[]) => {
    if (applicant) {
      setChanging(() => allFields.some((field) => field.value !== applicant![field.name[0] as keyof Applicant]));
    }

    if (allFields.some((field) => field.errors.length)) {
      setChanging(() => false);
    }
  };

  useEffect(() => {
    if (updateApplicant.data) {
      message.success('Assistant info has been successfully updated');
      setEdit(() => true);
      setChanging(() => false);
      fetch();
      setLoading(false);
    }
  }, [updateApplicant.data]);

  useEffect(() => {
    if (passwordCreate.data) {
      message.success('Password reset link was sent successfully');
    }
  }, [passwordCreate.data]);

  useEffect(() => {
    form.setFieldsValue({
      ...applicant,
      experiences: applicant?.experiences?.map((exp) => exp.id),
      address: applicant?.user?.address,
      firstName: applicant?.user?.firstName,
      lastName: applicant?.user?.lastName,
      phone: applicant?.user?.phone,
      email: applicant?.user?.email,
      indemnityProvider: applicant?.indemnityProvider?.id,
    });
  }, [applicant, form, isEdit]);

  useMessageError([updateApplicant, passwordCreate]);

  useEffect(() => {
    setLoading(false);
  }, [updateApplicant.error, passwordCreate.error]);

  return (
    <>
      <ChangePhone isModalOpen={phoneModalOpen} setIsModalOpen={setPhoneModalOpen} />
      <ChangeEmail isModalOpen={emailModalOpen} setIsModalOpen={setEmailModalOpen} fetch={fetch} />
      <ChangePassword isModalOpen={passwordModalOpen} setIsModalOpen={setPasswordModalOpen} fetch={fetch} />
      <Form<Applicant> form={form} layout="vertical" onFieldsChange={onFieldsChange}>
        <Reviewed name="personalInfoNotes" />
        <Notes name="personalInfoNotes" />
        <Wrapper>
          <Typography.Title level={5} style={{ margin: 0 }}>
            General details
          </Typography.Title>

          <Experiences disabled={!isAdmin || isPending} />
          {!isAdmin && (
            <Typography.Text>
              If you want to change your experience, please contact us at
              <NavLink to="mailto:support@lokem.com.au"> support@lokem.com.au</NavLink>
            </Typography.Text>
          )}

          <Row gutter={[16, 4]} style={{ marginTop: '12px' }}>
            <General disabled={isPending} />
            <Col span={12}>
              <Form.Item name="phone" label="Phone" style={{ marginBottom: 0 }} rules={[validationRules]}>
                <PhoneNumberInput placeholder="Phone" disabled={!isAdmin || isPending} />
              </Form.Item>
              {!isAdmin && (
                <Button className={styles.btn} type="primary" ghost onClick={() => setPhoneModalOpen(true)}>
                  Change phone
                </Button>
              )}
              {isVerified && isAdmin && (
                <>
                  <Typography.Text className={styles.resetLink} strong>
                    Send password reset link
                  </Typography.Text>
                  <Button
                    loading={passwordCreate.loading}
                    type="primary"
                    onClick={() => passwordCreate.fetch({ email: applicant!.user!.email })}
                    ghost
                  >
                    Send link
                  </Button>
                </>
              )}
            </Col>
            <Col span={12}>
              <Form.Item name="email" label="Email" style={{ marginBottom: 0 }} rules={[validationRules]}>
                <Input disabled={!isAdmin || isPending} />
              </Form.Item>
              {!isAdmin && <ReceiveEmails needUpdate />}
              {!isAdmin && (
                <>
                  <Button className={styles.btn} type="primary" ghost onClick={() => setEmailModalOpen(true)}>
                    Change email
                  </Button>
                  <Button className={styles.btn} type="primary" ghost onClick={() => setPasswordModalOpen(true)}>
                    Reset password
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Wrapper>

        <AHPRAnumber disabled={isPending} />
        <Indemnity disabled={isPending} />
        <BankDetails disabled={isPending} />
        <Reviewed name="interviewNotes" />
        <Notes name="interviewNotes" />
        {isAdmin && <Interview isScheduled={fromValues?.interviewScheduled || false} />}
      </Form>

      {!isAdmin && (
        <Button type="link" href="mailto:support@lokem.com.au" style={{ padding: 0 }}>
          Request account deletion
        </Button>
      )}
    </>
  );
}
