import { DeleteOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useEffect } from 'react';
import { App, Button, Space } from 'antd';
import { getMessageInError } from '../../../../../hooks/fetch';
import { Client, useClientUpdate } from '../../../../../hooks/client';
import { useSimpleModal } from '../../../../Common/Modal/Simple';

import styles from './index.module.scss';

type RemoveDomain = {
  clientId: string | undefined;
  client: Client;
  fetch: (action: string) => void;
  domainId: string;
};

export default function RemoveDomain({
  clientId, client, fetch, domainId,
}: RemoveDomain) {
  const { message } = App.useApp();
  const updateClient = useClientUpdate();
  const { open, contextHolder } = useSimpleModal();

  useEffect(() => {
    if (updateClient.data) {
      message.info('The surgeon was successfully deleted!');
      fetch('fetch');
    }
  }, [updateClient.data]);

  useEffect(() => {
    if (updateClient.error) {
      message.error(getMessageInError(updateClient.error));
      updateClient.clearError();
    }
  }, [updateClient.error]);

  return (
    <>
      <Button
        type="link"
        className={styles.icon}
        onClick={(event) => {
          event.stopPropagation();
          open({
            icon: <CloseCircleFilled style={{ color: '#FF4D4F' }} />,
            title: 'Delete surgeon',
            content: 'Are you sure you want to delete the surgeon?',
            cancelText: 'Cancel',
            okText: 'Delete',
            onOk: () => {
              updateClient.fetch(
                { domains: client.domains.filter((domain) => domain.id !== domainId).map((domain) => domain.id) },
                clientId,
              );
            },
          });
        }}
      >
        <DeleteOutlined />
      </Button>
      <Space onClick={(e) => e.stopPropagation()}>{contextHolder}</Space>
    </>
  );
}
