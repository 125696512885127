import { CloseOutlined } from '@ant-design/icons';
import { Space, Button, Modal } from 'antd';
import type { CollapseProps } from 'antd';
import { useShiftActionContext } from '../../../Pages/Shift/Create/Context';
import TopPanel from '../../TopPanel';
import Collapse from '../Collapse';
import DomainRoleSummary from '../DomainRoleSummary';
import ApplicantsSummary from '../ApplicantsSummary';
import DateTimeDetail from '../DateTimeDetail';
import AllowancesSummary from '../AllowancesSummary';
import ShiftStatus from '../../../../enums/shift';

import styles from './index.module.scss';

export default function SummaryModal() {
  const {
    isSummary, setIsSummary, onCreate, setCreateNew, summary, mode, onEdit, shift, loading,
  } = useShiftActionContext();

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Date & Time',
      children: (
        <DateTimeDetail
          timeStart={summary?.datetimeStart || ''}
          timeEnd={summary?.datetimeEnd || ''}
          onCallTimeStart={summary?.onCallDatetimeStart || ''}
          onCallTimeEnd={summary?.onCallDatetimeEnd || ''}
          duration={
            summary?.shiftDuration
            && (summary.shiftDuration === 'multi_day' ? 'Create one multi-day shift' : 'Create one shift for each day')
          }
        />
      ),
    },
    {
      key: '2',
      label: 'Surgeon & Role',
      children: <DomainRoleSummary />,
    },
    {
      key: '3',
      label: 'Allowances',
      children: <AllowancesSummary />,
    },
    {
      key: '4',
      label: 'Assistants',
      children: <ApplicantsSummary />,
    },
  ];

  return (
    <Modal open={isSummary} centered footer={[]} maskClosable={false} className={styles.modal}>
      <div className={styles.wrapper}>
        <TopPanel
          padding
          title={(
            <Space size="small">
              <Button type="text" onClick={() => setIsSummary(false)} className={styles.closeBtn}>
                <CloseOutlined />
              </Button>
              {mode === 'edit' && 'Edit shift'}
              {mode === 'create' && 'Create shift'}
              {mode === 'copy' && 'Copy shift'}
            </Space>
          )}
          rightContent={(
            <Space size="middle">
              {mode === 'create' && (
                <>
                  <Button
                    type="primary"
                    ghost
                    loading={loading}
                    onClick={() => {
                      setCreateNew(true);
                      onCreate();
                    }}
                  >
                    Publish and create new
                  </Button>
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={() => {
                      setCreateNew(false);
                      onCreate();
                    }}
                  >
                    Publish
                  </Button>
                </>
              )}
              {mode === 'copy' && (
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateNew(false);
                    onCreate();
                  }}
                >
                  Publish
                </Button>
              )}
              {mode === 'edit' && (
                <>
                  {shift?.status === ShiftStatus.DRAFT ? (
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={() => {
                        setCreateNew(false);
                        onEdit('draft');
                      }}
                    >
                      Save Draft
                    </Button>
                  ) : null}
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={() => {
                      setCreateNew(false);
                      onEdit('publish');
                    }}
                  >
                    Publish
                  </Button>
                </>
              )}
            </Space>
          )}
        />

        <Collapse items={items} />
      </div>
    </Modal>
  );
}
