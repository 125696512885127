import { Select, Form } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';

export const RateOptions = [
  { value: 'no rate', label: 'No rate' },
  { value: 'hourly', label: 'Hourly' },
  { value: 'fixed', label: 'Fixed ' },
  { value: 'range', label: 'Range ' },
];

export default function RateType() {
  return (
    <Form.Item
      name="rateType"
      label="Rate"
      tooltip={{
        title: 'Adding a rate here will pre-populate the rate field on the shift creation page '
          + '(where you can change it if you wish.) The options are fixed, range, and hourly.',
        icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
      }}
      rules={[{ required: true, message: 'Required field!' }]}
    >
      <Select
        placeholder="Rate Type"
        style={{ width: '100%' }}
        options={RateOptions}
      />
    </Form.Item>
  );
}
