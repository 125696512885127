import { useEffect, useMemo } from 'react';
import { useClientsFilterStatusAllGet, useClientsFilterStatusGet } from '../../../../hooks/client';
import Checkboxes from '../../../Common/Filter/Checkboxes';
import SearchMenu from '../../../Common/Filter/Search/Menu';
import FilterMenu from '../../../Common/Filter/Menu';
import { getBudgeColor } from '../../../Common/Badge';
import FilterWrapper from '../../../Common/Filter/Wrapper';
import { useMessageError, useSelectData } from '../../../../hooks/common';

interface FilterProps {
  disabled?: boolean
  reload?: number
}

function Filter({ disabled, reload }: FilterProps) {
  const defaultClientsFilterStatuses = useClientsFilterStatusAllGet();
  const clientsFilterStatuses = useClientsFilterStatusGet();
  const statuses = useSelectData(clientsFilterStatuses, (data, id, item) => ({
    ...item,
    color: getBudgeColor(item.value),
  }));

  useMessageError([
    defaultClientsFilterStatuses, clientsFilterStatuses,
  ]);

  useEffect(() => {
    if (reload) {
      clientsFilterStatuses.fetch(clientsFilterStatuses.history);
    }
  }, [reload]);

  return (
    <FilterWrapper clearFilters disabled={disabled}>
      <FilterMenu>
        <SearchMenu
          name="search"
          title="Client"
        />

        <Checkboxes
          name="status"
          title="Client status"
          badge
          checkAll
          defaultHook={defaultClientsFilterStatuses}
          loading={clientsFilterStatuses.loading || defaultClientsFilterStatuses.loading}
          fetch={clientsFilterStatuses.fetch}
          items={statuses}
        />
      </FilterMenu>
    </FilterWrapper>
  );
}

Filter.defaultProps = {
  reload: undefined,
  disabled: false,
};

export default Filter;
