import { useState, useEffect } from 'react';
import {
  Col, Row, Typography, Space, List, Button, App,
} from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { useLocationContext } from '../../Context';
import MessageItem from '../MessageItem';
import LinkedModal from '../LinkedModal';
import { useOrientationCreate, useOrientationDelete, useOrientationUpdate } from '../../../../../hooks/orientation';
import SaveModal from '../SaveModal/index';
import CopyOrienation from '../CopyOrienation';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useMessageError } from '../../../../../hooks/common';
import { ReducedRole } from '../../../../../types/location';

import styles from './index.module.scss';

export default function Orientations() {
  const { open, contextHolder } = useSimpleModal();
  const deleteOrientation = useOrientationDelete();
  const updateOrientation = useOrientationUpdate();
  const {
    location, fetch, loading, setEdit, isChanging,
  } = useLocationContext();
  const [activeId, setActiveId] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const { message } = App.useApp();
  const createOrientation = useOrientationCreate();

  useEffect(() => {
    setEdit(() => false);
  }, []);

  useEffect(() => {
    if (deleteOrientation.data) {
      fetch();
      message.info('You have successfully deleted orientation');
    }
  }, [deleteOrientation.data]);

  useEffect(() => {
    if (createOrientation.data) {
      fetch();
      message.info('You have successfully created a new orientation');
      setActiveId(createOrientation.data.id);
      setEdit(() => true);
    }
  }, [createOrientation.data]);

  useEffect(() => {
    if (updateOrientation.data) {
      fetch();
      message.info('You have successfully unlinked orientation');
    }
  }, [updateOrientation.data]);

  useMessageError([createOrientation, deleteOrientation, updateOrientation]);

  const actions = (id: string, name: string, role: ReducedRole | null) => (
    <div className={styles.actions}>
      <span>
        <Button
          type="text"
          disabled={isChanging}
          size="small"
          className={styles.btn}
          onClick={(e) => {
            e.stopPropagation();
            setEdit(() => true);
            setActiveId(id);
          }}
        >
          <EditOutlined className={styles.icon} />
        </Button>

        {name !== 'General Package' && (
          <>
            <CopyOrienation id={id} />
            <Button
              type="text"
              disabled={isChanging}
              size="small"
              className={styles.btn}
              onClick={(e) => {
                e.stopPropagation();
                open({
                  icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
                  title: 'Remove orientation',
                  content: 'Are you sure you want remove this orientation?',
                  cancelText: 'Cancel',
                  okText: 'Remove',
                  centered: true,
                  onOk: () => {
                    deleteOrientation.fetch(id);
                    if (activeId === id) {
                      setActiveId('');
                    }
                  },
                });
              }}
            >
              <DeleteOutlined className={styles.icon} />
            </Button>
          </>
        )}
      </span>
      {role && (
        <div className={styles.unlink}>
          <Button
            type="link"
            danger
            onClick={(e) => {
              e.stopPropagation();
              updateOrientation.fetch({ locationRole: null }, id);
            }}
          >
            Unlink
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <Space className={styles.wrapper} direction="vertical">
      <SaveModal isModalOpen={saveModalOpen} setModalOpen={setSaveModalOpen} />
      <LinkedModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} activeId={activeId} />
      <Row gutter={[16, 0]}>
        <Col span={11}>
          <Typography.Title style={{ margin: 0 }} level={5}>
            Orientation Templates
          </Typography.Title>
          <Typography.Paragraph className={styles.text}>
            Orientation Packages are sent via email to successful assistants who have been chosen for the shift. You can
            link a package to a particular role or simply select it when creating a shift.
          </Typography.Paragraph>
          <List
            className={styles.orientations}
            itemLayout="horizontal"
            dataSource={location?.orientations || []}
            loading={loading || deleteOrientation.loading || updateOrientation.loading}
            renderItem={(item) => (
              <List.Item
                className={clsx(styles.item, activeId === item.id ? styles.active : '')}
                actions={location!.isActive ? [actions(item.id, item.name, item.locationRole)] : []}
                onClick={() => {
                  if (isChanging) {
                    setSaveModalOpen(true);
                  } else {
                    setEdit(() => false);
                    setActiveId(item.id);
                  }
                }}
              >
                <List.Item.Meta
                  title={item?.name}
                  description={
                    item?.locationRole ? (
                      <>
                        <Button
                          onClick={() => setIsModalOpen(true)}
                          type="link"
                          disabled={isChanging || !location!.isActive}
                        >
                          Linked to:
                        </Button>
                        {' '}
                        {item.locationRole.name}
                      </>
                    ) : (
                      <Button
                        onClick={() => setIsModalOpen(true)}
                        type="link"
                        disabled={isChanging || !location!.isActive}
                      >
                        Link to role
                      </Button>
                    )
                  }
                />
              </List.Item>
            )}
          />
          <Button
            onClick={() => createOrientation.fetch({
              location: location!.id,
              message: '',
            })}
            className={styles.createBtn}
            disabled={isChanging || !location!.isActive}
          >
            <PlusOutlined />
            Create new
          </Button>
        </Col>

        <Col span={13}>{activeId && <MessageItem activeId={activeId} setActiveId={setActiveId} />}</Col>
      </Row>
      {contextHolder}
    </Space>
  );
}
