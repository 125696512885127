import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FilterWrapper from '../../../Common/Filter/Wrapper';
import Checkboxes from '../../../Common/Filter/Checkboxes';
import FilterMenu from '../../../Common/Filter/Menu';
import Select from '../../../Common/Filter/Select';
import { useDomainShiftsGet } from '../../../../hooks/domain';
import { useLocationsShiftsSelectGet } from '../../../../hooks/location';
import { useMessageError, useSelectData } from '../../../../hooks/common';
import { useSearchParams, useWatchSearchParam } from '../../../../hooks/useSearchParams';
import { useClientsSelectAllGet, useClientsSelectShiftGet } from '../../../../hooks/client';
import { useShiftStatusesGet } from '../../../../hooks/shift';
import { useLocationsRolesSelectGet } from '../../../../hooks/locationsRoles';
import { Applicant } from '../../../../types/applicant';
import { useApplicantsSelectShiftsGet } from '../../../../hooks/applicant';
import { getShiftStatus } from '../../../../enums/shift';
import { getValidSearchParams } from '../../../../utils';
import Sider from '../Common/Sider';
import { useRosterContext } from '../Context';

interface FiltersProps {
  disabled?: boolean;
}

function Filter({ disabled }: FiltersProps) {
  const { siderData } = useRosterContext();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultLocationsGet = useLocationsShiftsSelectGet();
  const locationsGet = useLocationsShiftsSelectGet();
  const locations = useSelectData(locationsGet);
  const locationsParam = useWatchSearchParam('locations');

  const defaultDomainShiftsGet = useDomainShiftsGet();
  const domainShiftsGet = useDomainShiftsGet();
  const domains = useSelectData(domainShiftsGet);

  const defaultClientsSelectShiftGet = useClientsSelectAllGet();
  const clientsSelectShiftGet = useClientsSelectShiftGet();
  const clientsShift = useSelectData(clientsSelectShiftGet);

  const defaultShiftStatusesGet = useShiftStatusesGet();
  const shiftStatusesGet = useShiftStatusesGet();
  const shiftStatuses = useSelectData(shiftStatusesGet);

  const defaultLocationsRolesGet = useLocationsRolesSelectGet();
  const locationsRolesGet = useLocationsRolesSelectGet();
  const locationsRoles = useSelectData(locationsRolesGet);

  const defaultApplicantsSelectGet = useApplicantsSelectShiftsGet();
  const applicantsSelectGet = useApplicantsSelectShiftsGet();
  const applicantsSelect = useSelectData<Applicant[]>(applicantsSelectGet, (data, id, item) => ({
    ...item,
    label: data[id].user?.fullName,
  }));

  const isApplicant = pathname.includes('/roster/applicant');

  useMessageError([
    defaultLocationsGet,
    locationsGet,
    defaultDomainShiftsGet,
    domainShiftsGet,
    defaultClientsSelectShiftGet,
    clientsSelectShiftGet,
    defaultShiftStatusesGet,
    shiftStatusesGet,
    defaultLocationsRolesGet,
    locationsRolesGet,
    defaultApplicantsSelectGet,
    applicantsSelectGet,
  ]);

  useEffect(() => {
    if (locationsGet.data) {
      const clients = locationsGet.data.filter((item) => locationsParam.includes(item.id));

      if (clients.length) {
        setSearchParams({
          ...getValidSearchParams('*', searchParams),
          clients: clients.map((client) => client.client.id),
        });
      }
    }
  }, [locationsParam]);

  return (
    <>
      <FilterWrapper clearFilters disabled={disabled}>
        <FilterMenu>
          <Select
            title="Surgeons"
            name="domains"
            items={domains}
            defaultHook={defaultDomainShiftsGet}
            loading={domainShiftsGet.loading || defaultDomainShiftsGet.loading}
            fetch={domainShiftsGet.fetch}
          />
          <Checkboxes
            title="Client"
            name="clients"
            items={clientsShift}
            defaultHook={defaultClientsSelectShiftGet}
            loading={clientsSelectShiftGet.loading || defaultClientsSelectShiftGet.loading}
            fetch={clientsSelectShiftGet.fetch}
            checkAll
            search
            uncheck
          />
          <Select
            title="Locations"
            name="locations"
            items={locations}
            defaultHook={defaultLocationsGet}
            loading={locationsGet.loading || defaultLocationsGet.loading}
            fetch={locationsGet.fetch}
            params={{ pageSize: 1000 }}
            mode="multiple"
          />
          <Checkboxes
            title="Shift status"
            name="statuses"
            items={shiftStatuses.map((status) => ({
              ...status,
              label: getShiftStatus(status.label),
            }))}
            decorateData={(d, i, result) => ({
              ...result,
              label: getShiftStatus(result.label),
            })}
            defaultHook={defaultShiftStatusesGet}
            loading={shiftStatusesGet.loading || defaultShiftStatusesGet.loading}
            fetch={shiftStatusesGet.fetch}
            checkAll
            badge
          />

          {isApplicant ? (
            <Checkboxes
              title="Assistants"
              name="applicants"
              items={applicantsSelect}
              decorateData={(data, id, item) => ({
                ...item,
                label: data[id].user?.fullName,
              })}
              defaultHook={defaultApplicantsSelectGet}
              loading={applicantsSelectGet.loading || defaultApplicantsSelectGet.loading}
              fetch={applicantsSelectGet.fetch}
              checkAll
              uncheck
              search
            />
          ) : (
            <Checkboxes
              title="Role"
              name="roles"
              items={locationsRoles}
              defaultHook={defaultLocationsRolesGet}
              loading={locationsRolesGet.loading || defaultLocationsRolesGet.loading}
              fetch={locationsRolesGet.fetch}
              checkAll
              search
              keyAsArray
            />
          )}
        </FilterMenu>
      </FilterWrapper>

      {siderData && siderData.length && <Sider />}
    </>
  );
}

Filter.defaultProps = {
  disabled: false,
};

export default Filter;
