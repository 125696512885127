import { Space } from 'antd';
import type { CollapseProps } from 'antd';
import Collapse from '../Collapse';
import DateTimeDetail from '../DateTimeDetail';
import { useShiftContext } from '../../../Pages/Shift/Context';
import ViewItem from '../ViewItem';
import { getDaysBetween, getHoursBetween } from '../../../../utils';
import DomainRoleDetails from '../DomainRoleDetails';
import ApplicantView from '../ApplicantView';
import ApplicantDetails from '../ApplicantDetails';
import { useAuthState } from '../../../../store/auth';
import Loading from '../../Loading';
import AllowancesDetails from '../AllowancesDetails';

import styles from './index.module.scss';
import Logs from '../Logs';
import ShiftStatus from '../../../../enums/shift';

export default function Details() {
  const { user } = useAuthState();
  const { shift, isApplicant } = useShiftContext();
  const thisApplicantChoosen = user && user.applicant && user?.applicant?.id === shift?.applicant?.id;

  const start = shift?.multiShift ? shift.multiShift.datetimeStart : shift?.datetimeStart || '';
  const end = shift?.multiShift ? shift.multiShift.datetimeEnd : shift?.datetimeEnd || '';
  const defaultItems: CollapseProps['items'] = [
    {
      key: 'date',
      label: 'Date & Time',
      children: (
        <DateTimeDetail
          timeStart={start}
          timeEnd={end}
          onCallTimeStart={shift?.onCallDatetimeStart || ''}
          onCallTimeEnd={shift?.onCallDatetimeEnd || ''}
          duration={shift?.multiShift ? 'Create one multi-day shift' : ''}
        />
      ),
      extra: (
        <Space className={styles.extra}>
          <ViewItem span={24} text="Day(s)" value={getDaysBetween(start, end)} margin={false} />
          <ViewItem span={24} text="Hours" value={getHoursBetween(start, end)} margin={false} />
        </Space>
      ),
    },
    {
      key: 'domains',
      label: isApplicant ? 'Shift Information' : 'Surgeon & Role',
      children: <DomainRoleDetails />,
      extra: (
        <Space className={styles.extra}>
          <ViewItem span={24} text="Location" value={shift?.location?.name || 'Not set'} margin={false} />
          <ViewItem span={24} text="Role" value={shift?.locationRole?.name || 'Not set'} margin={false} />
        </Space>
      ),
    },
    {
      key: 'allowances',
      label: 'Allowances & Breaks',
      children: <AllowancesDetails />,
    },
  ];

  const applicantItems: CollapseProps['items'] = [
    {
      key: 'inductions',
      label: 'Inductions and Orientation',
      children: <ApplicantDetails />,
    },
  ];

  const adminItems: CollapseProps['items'] = [
    {
      key: 'applicants',
      label: 'Assistants',
      children: <ApplicantView />,
    },
  ];

  const logs : CollapseProps['items'] = [
    {
      key: 'logs',
      label: 'Logs',
      children: <Logs />,
    },
  ];
  const getItems = (): CollapseProps['items'] => {
    if (thisApplicantChoosen) {
      return [...defaultItems, ...applicantItems];
    }

    if (isApplicant) {
      return defaultItems;
    }

    if (!isApplicant && shift && shift.status === ShiftStatus.COMPLETED) {
      return [...defaultItems, ...adminItems, ...logs];
    }

    return [...defaultItems, ...adminItems];
  };

  return shift ? (
    <Collapse items={getItems()} defaultActive={[]} />
  ) : (
    <div className={styles.loading}>
      <Loading />
    </div>
  );
}
