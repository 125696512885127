import { useEffect, useState } from 'react';
import { App, Button, Modal } from 'antd';
import { StarFilled, StopOutlined } from '@ant-design/icons';
import { Applicant } from '../../../../types/applicant';
import {
  useApplicantStatusesCreate,
  useApplicantStatusesDelete,
  useApplicantStatusesUpdate,
} from '../../../../hooks/applicantStatuse';
import { useMessageError } from '../../../../hooks/common';

type AddPreferredProps = {
  applicant: Applicant;
  clientId: string;
  fetch: () => void;
  listName: string;
};

export default function StatusAction({
  applicant, clientId, listName, fetch,
}: AddPreferredProps) {
  const { message } = App.useApp();
  const name = ` ${applicant?.title} ${applicant?.user.firstName} ${applicant?.user.lastName} `;
  const createStatus = useApplicantStatusesCreate();
  const updateStatus = useApplicantStatusesUpdate();
  const deleteStatus = useApplicantStatusesDelete();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const status = applicant?.statuses?.find((sts) => sts.client.id === clientId);

  const getIcon = () => (listName === 'preferred' ? (
    <StarFilled style={{ color: '#FADB14', paddingRight: '6px' }} />
  ) : (
    <StopOutlined style={{ color: '#FF4D4F', paddingRight: '6px' }} />
  ));
  const getStatus = () => (status?.status === listName ? ` remove ${name} from ` : ` add ${name} to `);
  const getStatusCapitalize = () => (status?.status === listName ? ' Remove from ' : ' Add to ');

  const updateHandler = () => {
    updateStatus.fetch(
      {
        client: clientId,
        applicant: applicant?.id,
        status: listName,
      },
      status?.id,
    );
  };

  const createHandler = () => {
    createStatus.fetch({
      client: clientId,
      applicant: applicant?.id,
      status: listName,
    });
  };

  const deleteHandler = () => {
    deleteStatus.fetch(status?.id);
  };

  useEffect(() => {
    if (createStatus.data) {
      message.info(`The assistant has been successfully added to the ${listName} list!`);
      fetch();
      setIsModalOpen(false);
    }
  }, [createStatus.data]);

  useEffect(() => {
    if (updateStatus.data) {
      message.info(`The assistant has been successfully added to the ${listName} list!`);
      fetch();
      setIsModalOpen(false);
    }
  }, [updateStatus.data]);

  useEffect(() => {
    if (deleteStatus.data) {
      message.info(`The assistant was successfully removed from the ${listName} list!`);
      fetch();
      setIsModalOpen(false);
    }
  }, [deleteStatus.data]);

  useMessageError([createStatus, deleteStatus, updateStatus]);

  const handleOk = () => {
    if (status && status.status !== listName) {
      updateHandler();
    }

    if (status && status.status === listName) {
      deleteHandler();
    }

    if (!status) {
      createHandler();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title={(
          <>
            {getIcon()}
            {getStatusCapitalize()}
            {listName}
            {' '}
            list?
          </>
        )}
        open={isModalOpen}
        onOk={handleOk}
        okButtonProps={{ loading: createStatus.loading || deleteStatus.loading || updateStatus.loading }}
        okText="Yes"
        onCancel={handleCancel}
        centered
        style={{ maxWidth: '400px' }}
      >
        <div style={{ paddingLeft: '26px' }}>
          Are you sure you want to
          {getStatus()}
          {listName}
          {' '}
          list?
        </div>
      </Modal>

      <Button type="text" style={{ padding: '4px 6px', fontSize: '12px' }} onClick={() => setIsModalOpen(true)}>
        {getIcon()}
        {getStatusCapitalize()}
        {listName}
        {' '}
        list
      </Button>
    </>
  );
}
