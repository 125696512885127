import React, { useMemo } from 'react';
import {
  createSearchParams, NavLink, useLocation, useNavigate,
} from 'react-router-dom';
import {
  AimOutlined,
  DashboardOutlined,
  LockOutlined,
  DollarOutlined,
  FileSearchOutlined,
  HomeOutlined,
  LogoutOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined,
  UserSwitchOutlined,
  ControlOutlined,
} from '@ant-design/icons';
import { Menu as AntdMenu } from 'antd';
import { connect } from 'react-redux';
import { signOut } from '@triare/auth-redux/dist/saga/auth/signOut';
import { AnyObject } from '@triare/auth-redux';
import dayjs from 'dayjs';
import { generateDefaultUrl, Route } from '../../../routes';
import ApplicantProfile from '../../../pages/Profile/Applicant';
import ClientProfile from '../../../pages/Profile/Client';
import store, { RootState } from '../../../store';
import { moduleName, User } from '../../../store/auth';
import { isRoleEnough, UserRoles, UserStatus } from '../../../enums/user';
import Shift from '../../../pages/Shift';
import CreateShift from '../../../pages/Shift/Create';
import EditShift from '../../../pages/Shift/Edit';
import CopyShift from '../../../pages/Shift/Copy';
import ShiftList from '../../../pages/Shift/List';
import ClientList from '../../../pages/Client/List';
import ClientById from '../../../pages/Client';
import ClientCreate from '../../../pages/Client/Create';
import ClientEdit from '../../../pages/Client/Edit';
import ClientUserById from '../../../pages/Client/User';
import ClientUserCreate from '../../../pages/Client/User/Create';
import ClientUserEdit from '../../../pages/Client/User/Edit';
import UserList from '../../../pages/User/List';
import ClientLocationList from '../../../pages/Location/List/Client';
import AdminLocationList from '../../../pages/Location/List/Admin';
import ApplicantList from '../../../pages/Applicant/List';
import AdminList from '../../../pages/Admin/List';
import SettingList from '../../../pages/Setting/List';
import InvoiceList from '../../../pages/Invoice/List';
import Faq from '../../../pages/Faq';
import AddUser from '../../../pages/User/Add';
import UserById from '../../../pages/User';
import Applicant from '../../../pages/Applicant';
import Location from '../../../pages/Location';
import Admin from '../../../pages/Admin';
import AdminCreate from '../../../pages/Admin/Create';
import ClientUserList from '../../../pages/Client/User/List';
import Settings from '../../../pages/Setting';
import ShiftCalendar from '../../../pages/Shift/Calendar';
import NotificationsModal from '../../Common/NotificationsModal';
import Confirmation from '../../../pages/Shift/Confirmation';
import { useSimpleModal } from '../../Common/Modal/Simple';
import Notifications from '../../../pages/Notifications';
import Home from '../../../pages/Home';
import OpenShiftList from '../../../pages/Shift/Open/List';
import Privacy from '../../../pages/Privacy';
import { doesUserHaveAccess } from '../../../routes/PrivateRoute';
import ShiftConfirmationList from '../../../pages/Shift/Open/Confirmation/List';
import ContactUs from '../../../pages/ContactUs';
import GuestList from '../../../pages/Guest/List';
import { defaultStatuses } from '../../../enums/shift';
import { dateFormatISO } from '../../../contstant';
import RosterByRole from '../../../pages/Roster/Role';
import RosterByApplicant from '../../../pages/Roster/Applicant';
import TeamsList from '../../../pages/Teams/List';
import TeamById from '../../../pages/Teams';

import styles from './index.module.scss';

export interface MenuRoute extends Route {
  ignoreMenu?: boolean;

  children?: MenuRoute[];
}

const checkApplicantRoute = (user: User) => !(user?.role !== UserRoles.APPLICANT || (
  user?.status === UserStatus.REJECTED
));

export const routes: MenuRoute[] = [
  {
    privateRoute: true,
    roles: [
      checkApplicantRoute,
    ],
    align: 'left',
    name: 'Dashboard',
    icon: <DashboardOutlined />,
    route: {
      path: '/dashboard',
      elements: {
        [UserRoles.APPLICANT]: <Home />,
        '*': <ShiftList />,
      },
    },
  },
  {
    privateRoute: true,
    roles: [
      checkApplicantRoute,
      UserRoles.MANAGER,
      UserRoles.CLIENT_ADMIN,
      UserRoles.LOKEM_ADMIN,
      UserRoles.ROOT,
    ],
    align: 'left',
    icon: (user) => (user?.role === UserRoles.APPLICANT ? <ScheduleOutlined /> : <UnorderedListOutlined />),
    name: (user) => (user?.role === UserRoles.APPLICANT ? 'My Shifts' : 'Shifts'),
    disabled: (user) => (user?.status === UserStatus.PENDING),
    route: {
      path: 'shifts',
      params: (user) => {
        if (user?.role === UserRoles.APPLICANT) {
          return {
            statuses: defaultStatuses,
          };
        }

        return {
          statuses: defaultStatuses,
          from: dayjs().utc().startOf('day').format(dateFormatISO),
        };
      },
      element: <ShiftList />,
    },
    children: [
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.APPLICANT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
        name: 'Shift: calendar',
        route: {
          path: 'calendar',
          element: <ShiftCalendar />,
        },
      },
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
        name: 'Create shift',
        route: {
          path: 'create',
          element: <CreateShift />,
        },
      },
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.APPLICANT, UserRoles.ROOT],
        name: 'Shift confirmation',
        route: {
          path: 'confirmation',
          element: <Confirmation />,
        },
      },
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.APPLICANT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
        name: 'Shift',
        route: {
          path: ':shiftId',
          element: <Shift />,
        },
        children: [
          {
            ignoreMenu: true,
            privateRoute: true,
            roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
            name: 'Shift: edit',
            route: {
              path: 'edit',
              element: <EditShift />,
            },
          },
          {
            ignoreMenu: true,
            privateRoute: true,
            roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
            name: 'Shift: copy',
            route: {
              path: 'copy',
              element: <CopyShift />,
            },
          },
        ],
      },
    ],
  },
  {
    privateRoute: true,
    roles: [
      UserRoles.LOKEM_ADMIN,
      UserRoles.ROOT,
    ],
    align: 'left',
    icon: <ControlOutlined />,
    name: 'Roster',
    route: {
      path: 'roster',
      params: () => ({ statuses: defaultStatuses }),
      element: <RosterByRole />,
    },
    children: [
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [
          UserRoles.LOKEM_ADMIN,
          UserRoles.ROOT,
        ],
        name: 'Roster by Role',
        route: {
          path: 'role',
          element: <RosterByRole />,
        },
      },

      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [
          UserRoles.LOKEM_ADMIN,
          UserRoles.ROOT,
        ],
        name: 'Roster by Assistant',
        route: {
          path: 'applicant',
          element: <RosterByApplicant />,
        },
      },
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
        name: 'Create shift',
        route: {
          path: 'create',
          element: <CreateShift />,
        },
      },

      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
        name: 'Shift',
        route: {
          path: ':shiftId',
          element: <Shift />,
        },
        children: [
          {
            ignoreMenu: true,
            privateRoute: true,
            roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
            name: 'Shift: edit',
            route: {
              path: 'edit',
              element: <EditShift />,
            },
          },
        ],
      },
    ],
  },
  {
    privateRoute: true,
    roles: [checkApplicantRoute],
    align: 'left',
    icon: <UnorderedListOutlined />,
    name: 'Open Shifts',
    route: {
      path: 'open-shifts',
      element: <OpenShiftList />,
    },
    children: [
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.APPLICANT],
        name: 'Shift: confirmation',
        route: {
          path: 'confirmation',
          element: <ShiftConfirmationList />,
        },
      },
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.APPLICANT],
        name: 'Shift: calendar',
        route: {
          path: 'calendar',
          element: <ShiftCalendar />,
        },
      },
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.APPLICANT],
        name: 'Shift confirmation',
        route: {
          path: 'confirmation',
          element: <Confirmation />,
        },
      },
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.APPLICANT],
        name: 'Shift',
        route: {
          path: ':shiftId',
          element: <Shift />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
    align: 'left',
    icon: <HomeOutlined />,
    name: 'Clients',
    route: {
      path: 'clients',
      element: <ClientList />,
    },
    children: [
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
        name: 'Client: detail page',
        route: {
          path: ':clientId',
          element: <ClientById />,
        },
        children: [
          {
            ignoreMenu: true,
            privateRoute: true,
            roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
            name: 'Client: edit',
            route: {
              path: 'edit',
              element: <ClientEdit />,
            },
          },
          {
            ignoreMenu: true,
            privateRoute: true,
            roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
            name: 'Client users',
            route: {
              path: 'users',
              element: <ClientUserList />,
            },
            children: [
              {
                ignoreMenu: true,
                privateRoute: true,
                roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
                name: 'Client users: create',
                route: {
                  path: 'create',
                  element: <ClientUserCreate />,
                },
              },
              {
                ignoreMenu: true,
                privateRoute: true,
                roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
                name: 'Client users: detail page',
                route: {
                  path: ':userId',
                  element: <ClientUserById />,
                },
                children: [
                  {
                    ignoreMenu: true,
                    privateRoute: true,
                    roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
                    name: 'Client users: edit',
                    route: {
                      path: 'edit',
                      element: <ClientUserEdit />,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
        name: 'Client: create',
        route: {
          path: 'create',
          element: <ClientCreate />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    roles: [UserRoles.CLIENT_ADMIN],
    align: 'left',
    icon: <TeamOutlined />,
    name: 'Users',
    route: {
      path: 'users',
      element: <UserList />,
    },
    children: [
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.CLIENT_ADMIN],
        name: 'Add user',
        route: {
          path: 'add',
          element: <AddUser />,
        },
      },
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
        name: 'User',
        route: {
          path: ':userId',
          element: <UserById />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    roles: [UserRoles.CLIENT_ADMIN, UserRoles.MANAGER, UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
    align: 'left',
    icon: <AimOutlined />,
    name: 'Locations',
    route: {
      path: 'locations',
      element: <ClientLocationList />,
      elements: {
        [UserRoles.MANAGER]: <ClientLocationList />,
        [UserRoles.CLIENT_ADMIN]: <ClientLocationList />,
        [UserRoles.ROOT]: <AdminLocationList />,
        [UserRoles.LOKEM_ADMIN]: <AdminLocationList />,
      },
    },
    children: [
      {
        roles: [UserRoles.CLIENT_ADMIN, UserRoles.MANAGER, UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
        ignoreMenu: true,
        privateRoute: true,
        name: 'Location',
        route: {
          path: ':locationId',
          element: <Location />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
    align: 'left',
    icon: <FileSearchOutlined />,
    name: 'Assistants',
    route: {
      path: 'applicants',
      params: (user) => (isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN)
        ? { userStatuses: ['active', 'pending'] } : undefined),
      element: <ApplicantList />,
    },
    children: [
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
        name: 'Assistant',
        route: {
          path: ':applicantId',
          elements: {
            [UserRoles.MANAGER]: <Applicant />,
            [UserRoles.CLIENT_ADMIN]: <Applicant />,
            [UserRoles.LOKEM_ADMIN]: <ApplicantProfile />,
            [UserRoles.ROOT]: <ApplicantProfile />,
          },
        },
      },
      {
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
        icon: <UserSwitchOutlined />,
        name: 'Incomplete Registrations',
        ignoreMenu: true,
        route: {
          path: 'incomplete-registrations',
          element: <GuestList />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
    align: 'left',
    ignoreMenu: true,
    name: 'Teams',
    route: {
      path: 'teams',
      element: <TeamsList />,
    },
    children: [
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
        name: 'team',
        route: {
          path: ':teamId',
          elements: {
            [UserRoles.LOKEM_ADMIN]: <TeamById />,
            [UserRoles.ROOT]: <TeamById />,
          },
        },
      },
    ],
  },

  {
    privateRoute: true,
    roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
    align: 'left',
    icon: <TeamOutlined />,
    name: 'Admins',
    route: {
      path: 'admins',
      element: <AdminList />,
    },
    children: [
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
        name: 'Admin',
        route: {
          path: ':adminId',
          element: <Admin />,
        },
      },
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.ROOT],
        name: 'Admin: create',
        route: {
          path: 'create',
          element: <AdminCreate />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
    align: 'left',
    icon: <SettingOutlined />,
    name: 'Settings',
    route: {
      path: 'settings',
      elements: {
        [UserRoles.LOKEM_ADMIN]: <Settings />,
        [UserRoles.ROOT]: <Settings />,
        [UserRoles.CLIENT_ADMIN]: <SettingList />,
        [UserRoles.MANAGER]: <SettingList />,
      },
    },
    children: [
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
        name: 'Settings',
        route: {
          path: ':tab',
          element: <Settings />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT],
    align: 'left',
    icon: <DollarOutlined />,
    name: 'Invoices',
    route: {
      path: 'invoices',
      element: <InvoiceList />,
    },
  },
  {
    privateRoute: true,
    roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.APPLICANT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
    align: 'right',
    icon: <QuestionCircleOutlined style={{ fontSize: 18 }} />,
    route: {
      path: 'faq',
      element: <Faq />,
    },
  },
  {
    privateRoute: true,
    roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.APPLICANT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
    align: 'right',
    icon: <NotificationsModal iconClassName={undefined} />,
    name: ' ',
    children: [
      {
        ignoreMenu: true,
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.APPLICANT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
        name: 'notifications',
        route: {
          path: 'notifications',
          element: <Notifications />,
        },
      },
    ],
  },
  {
    privateRoute: true,
    align: 'right',
    icon: <UserOutlined style={{ fontSize: 18 }} />,
    className: 'icon-padding-mini-sub',
    children: [
      {
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.APPLICANT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
        icon: <UserOutlined />,
        name: 'Profile',
        route: {
          path: 'profile',
          elements: {
            [UserRoles.APPLICANT]: <ApplicantProfile />,
            '*': <ClientProfile />,
          },
        },
      },
      {
        privateRoute: true,
        roles: [UserRoles.LOKEM_ADMIN, UserRoles.ROOT, UserRoles.APPLICANT, UserRoles.CLIENT_ADMIN, UserRoles.MANAGER],
        icon: <MessageOutlined />,
        name: 'Contact us',
        route: {
          path: 'contact-us',
          element: <ContactUs />,
        },
      },
      {
        privateRoute: false,
        icon: <LockOutlined />,
        name: 'Privacy & Terms',
        route: {
          path: 'privacy',
          element: <Privacy />,
        },
      },
      {
        privateRoute: true,
        icon: <LogoutOutlined />,
        name: 'Sign out',
        onClick: () => {
          store.dispatch(signOut());
        },
      },
    ],
  },
];

export const paramsToString = (user?: User | null, params?: ((user: User) => string) | AnyObject | string) => {
  if (!user) {
    return '';
  }

  if (typeof params === 'string') {
    return '?params';
  }

  if (typeof params === 'function') {
    let result = params(user);

    if (result) {
      if (typeof result === 'object') {
        result = createSearchParams(result).toString();
      }

      return `?${result}`;
    }

    return '';
  }

  if (params && typeof params === 'object') {
    return `?${createSearchParams(params).toString()}`;
  }

  return '';
};

const createItem = ({
  // eslint-disable-next-line
  route, children, ignoreMenu, privateRoute, ...data
}: Route, user: User) => {
  let childrenList;

  if (children && children.length) {
    childrenList = children.filter(({ ignoreMenu: childrenIgnoreMenu }) => childrenIgnoreMenu !== true);

    if (childrenList.length) {
      childrenList = childrenList.map(
        // eslint-disable-next-line
        ({ ignoreMenu: childrenIgnoreMenu, privateRoute: childrenPrivateRoute, ...child }) => ({
          ...child,
          label: child.route ? (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <NavLink to={`/${child.route.path}${paramsToString(user, child.route.path)}`}>
              {child.name || child.key}
            </NavLink>
          ) : (
            child.name
          ),
          key: child.route ? `/${child.route.path}` : `/${child.key || child.name}`,
        }),
      );
    }
  }

  const name = typeof data.name === 'function' ? data.name(user) : data.name;

  return {
    ...data,
    children: childrenList && childrenList.length ? childrenList : undefined,
    label: route ? (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      <NavLink to={`/${route.path}${paramsToString(user, route.params)}`}>{name}</NavLink>
    ) : (
      name
    ),
    key: route ? `/${route.path}` : `/${data.key || name}`,
  };
};

export const menuLeft = routes.filter(({ ignoreMenu }) => ignoreMenu !== true).filter(({ align }) => align === 'left');

export const menuRight = routes
  .filter(({ ignoreMenu }) => ignoreMenu !== true)
  .filter(({ align }) => align === 'right');

// eslint-disable-next-line
function filter(list: any[], user: User) {
  const newList = list.map((item) => ({ ...item, children: item.children ? [...item.children] : undefined }));

  return newList.filter((item) => {
    if (item && item.children && item.children.length) {
      // eslint-disable-next-line no-param-reassign
      item.children = filter(item.children, user);
    }

    return item?.roles ? doesUserHaveAccess(item?.roles, user) : true;
  });
}

export interface MenuProps {
  user: User;
}

function Menu({ user }: MenuProps): React.ReactNode {
  const navigate = useNavigate();
  const { open, contextHolder } = useSimpleModal();
  const { pathname } = useLocation();
  const menuLeftFiltered = useMemo(
    () => filter(
      menuLeft.map((data) => createItem(data, user)),
      user,
    ).map((item) => ({
      ...item,
      disabled: typeof item.disabled === 'function' ? item.disabled(user) : item.disabled,
      name: typeof item.name === 'function' ? item.name(user) : item.name,
      icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
    })),
    [user],
  );
  const menuRightFiltered = useMemo(() => {
    const list = filter(
      menuRight.map((data) => createItem(data, user)),
      user,
    ).map((item) => ({
      ...item,
      name: typeof item.name === 'function' ? item.name(user) : item.name,
      icon: typeof item.icon === 'function' ? item.icon(user) : item.icon,
    }));

    if (list[2] && list[2].children && list[2].children[3]) {
      list[2].children[3].onClick = () => {
        open({
          icon: <LogoutOutlined style={{ color: '#FF4D4F' }} />,
          title: 'Log out',
          content: 'Are you sure you want to Log out?',
          cancelText: 'Cancel',
          centered: true,
          okText: 'Yes',
          onOk: () => {
            store.dispatch(signOut());
            navigate('/');
          },
        });
      };
    }

    return list;
  }, [user]);

  const activeMenuItem = pathname.split('/')[1] === '' && user?.role !== UserRoles.APPLICANT
    ? `/shifts${generateDefaultUrl(user)}`
    : `/${pathname.split('/')[1]}`;

  return (
    <div className={styles.menu}>
      <AntdMenu
        theme="light"
        mode="horizontal"
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        defaultSelectedKeys={[activeMenuItem]}
        items={menuLeftFiltered}
        style={{ minWidth: 0, flex: 'auto' }}
      />
      <div style={{ justifyContent: 'end', paddingRight: 16, display: 'flex' }}>
        <AntdMenu
          className={styles.rightMenu}
          theme="light"
          mode="horizontal"
          selectable={false}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          defaultSelectedKeys={[activeMenuItem]}
          items={menuRightFiltered}
          style={{ minWidth: 0, flex: 'auto', justifyContent: 'end' }}
        />
      </div>
      {contextHolder}
    </div>
  );
}

export default connect((state: RootState) => ({
  user: state[moduleName].user,
}))(Menu);
