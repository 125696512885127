import React, { useEffect, useState } from 'react';
import {
  Badge, Button, App, Space, Tooltip, Modal as AntdModal, Input, Typography,
} from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import type { ProColumns } from '@ant-design/pro-components';
import { ProCoreActionType } from '@ant-design/pro-utils/es/typing';
import { NavLink } from 'react-router-dom';
import { getBudgeColor } from '../../../Common/Badge';
import Table from '../../../Common/Table';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { useMessageError } from '../../../../hooks/common';
import {
  Team,
  TeamsPaginGetParams,
  teamsGetParamList,
  useTeamCreate,
  useTeamDelete,
  useTeamsPaginGet,
} from '../../../../hooks/team';
import RadioSwitch from '../../Guest/RadioSwitch';
import Modal from '../Modal';
import SettingsProvider, { useSettingsContext } from '../Context';
import styles from './index.module.scss';

export interface ActionProps<D> {
  dom: React.ReactNode;
  entity: D;
  index: number;
  action: ProCoreActionType | undefined;
}

const columns: ProColumns<Team>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    render: (text, entity) => <NavLink to={entity.id}>{text}</NavLink>,
  },
  {
    title: 'Members',
    dataIndex: 'Members',
    key: 'Members',
    sorter: true,
    render: (text, entity) => entity.membersCount,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text, entity) => (
      <Badge
        className={styles.status}
        color={getBudgeColor(entity.isActive ? 'active' : 'inactive')}
        text={entity.isActive ? 'active' : 'inactive'}
      />
    ),
  },
];

function Content() {
  const teamCreate = useTeamCreate();
  const { open, contextHolder } = useSimpleModal();
  const teams = useTeamsPaginGet();
  const { setModalOpen, members } = useSettingsContext();
  const [searchParams] = useSearchParams();
  const teamDelete = useTeamDelete();
  const { message } = App.useApp();
  const [createModal, setCreateModal] = useState(false);
  const [teamName, setTeamName] = useState('');

  const getTeams = () => {
    const props: TeamsPaginGetParams = {};

    teamsGetParamList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    teams.fetch(props);
  };

  useEffect(getTeams, [searchParams]);

  useEffect(() => {
    if (teamDelete.data) {
      getTeams();
      message.success('You have successfully deleted this team.');
    }
  }, [teamDelete.data]);

  useEffect(() => {
    if (teamCreate.data) {
      getTeams();
      message.success('You have successfully created team.');
      setModalOpen(false);
    }
  }, [teamCreate.data]);

  const actions = ({ entity }: ActionProps<Team>) => (
    <Space>
      <Tooltip title="Edit">
        <NavLink to={`${entity.id}`}>
          <EditOutlined />
        </NavLink>
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          type="link"
          className={styles.btn}
          danger
          onClick={(e) => {
            e.preventDefault();
            open({
              icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
              title: 'Delete team',
              // eslint-disable-next-line max-len
              content: `Are you sure you want to delete ${entity.name}? Shifts assigned to assistants in this team will not be affected.`,
              cancelText: 'Cancel',
              okText: 'Delete',
              onOk: () => teamDelete.fetch(entity.id),
            });
          }}
        >
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </Space>
  );

  const createTeam = () => {
    setTeamName('');
    setModalOpen(false);
    teamCreate.fetch({
      name: teamName,
      isActive: true,
      members: members!,
    });
  };

  useMessageError([teams, teamDelete, teamCreate]);

  return (
    <div>
      <AntdModal
        title="Create team"
        open={createModal}
        onOk={() => {
          setCreateModal(false);
          setModalOpen(true);
        }}
        onCancel={() => {
          open({
            icon: <ExclamationCircleOutlined style={{ color: '#FAAD14' }} />,
            title: 'Cancel team creation',
            content: 'Are you sure that you want to cancel team creation?',
            cancelText: 'Back to creation',
            okText: 'Cancel',
            onOk: () => setCreateModal(false),
          });
        }}
        okText="Create team"
        okButtonProps={{ disabled: !teamName }}
      >
        <Typography.Text>Please enter a name for the new team </Typography.Text>
        <Input
          className={styles.modalInput}
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          placeholder="Team name"
        />
      </AntdModal>

      <Modal addMembers={createTeam} />
      <Table<Team>
        rowKey="id"
        search={false}
        headerTitle={(
          <Space>
            Teams
            <RadioSwitch defaultActive="teams" />
          </Space>
        )}
        toolBarRender={() => [
          <Button key="create" onClick={() => setCreateModal(true)} type="primary">
            Create team
          </Button>,
        ]}
        actions={actions}
        columnsState={{ persistenceKey: 'pro-table-teams', persistenceType: 'localStorage' }}
        options={{
          reload: false,
          density: false,
        }}
        columns={columns}
        loading={teams.loading}
        pagingData={teams.data}
      />

      {contextHolder}
    </div>
  );
}

export default function TeamList() {
  return (
    <SettingsProvider>
      <Content />
    </SettingsProvider>
  );
}
