import { useEffect } from 'react';
import {
  Button, Form, Input, Checkbox, Select, Badge, Tooltip, Typography,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { FetchDelete, FetchSuccess, PagingDataResponse } from '../../../../../hooks/fetch';
import { getBudgeColor } from '../../../../Common/Badge';
import { useMessageError } from '../../../../../hooks/common';
import Search from '../Search';
import Loading from '../../../../Common/Loading';
import Dropdown from '../Dropdown';
import Pagination from '../Pagination';
import { useSettingsContext } from '../../Context';
import { useDomainsValidate } from '../../../../../hooks/domain';

import styles from './index.module.scss';

interface Item {
  title: string;
  id: string;
  priority?: string;
}

interface FormListProps {
  nameButton: string;
  name: string;
  valueName: string;
  removeTitle: string;
  removeButtonText: string;
  placeholder: string;
  deleteAction: FetchDelete<FetchSuccess>;
  fetch?: () => void;
  items?: PagingDataResponse<Item> | { data: Item[] };
  requiredCheckbox?: boolean;
  loading?: boolean;
}

function FormList({
  nameButton,
  name: listName,
  deleteAction,
  removeTitle,
  removeButtonText,
  valueName,
  placeholder,
  fetch,
  items,
  requiredCheckbox,
  loading,
}: FormListProps) {
  const { open, contextHolder } = useSimpleModal();
  const { setModalOpen } = useSettingsContext();
  const validateDomain = useDomainsValidate();

  useEffect(() => {
    if (deleteAction.data) {
      deleteAction.clearResponse();
      if (fetch) {
        fetch();
      }
    }
  }, [deleteAction.data]);

  useMessageError([deleteAction, validateDomain]);

  // eslint-disable-next-line max-len
  // const checkIsRequiredResume = (key: number) => items && items.data[key]?.title.toLocaleLowerCase() === 'resume' && listName === 'documents';

  return (
    <>
      <div className={styles.wrapper}>
        {listName !== 'domains' && <Search />}
        {loading ? (
          <div className={styles.loading}>
            <Loading />
          </div>
        ) : (
          <Form.List name={listName}>
            {(fields) => (fields && fields.length
              ? fields.map(({ key, name, ...restField }) => (
                <div key={key} className={styles.row}>
                  <div className={styles.inner}>
                    <Form.Item {...restField} name={[name, 'id']} key={key} hidden>
                      <Input />
                    </Form.Item>
                    {listName === 'experiences' ? (
                      <Form.Item {...restField} name={[name, 'priority']}>
                        <Select
                          disabled
                          className={styles.badgeSelect}
                          options={[
                            {
                              value: 'high',
                              label: (
                                <Tooltip title="Display First">
                                  <Badge color={getBudgeColor('high')} />
                                </Tooltip>
                              ),
                            },
                            {
                              value: 'medium',
                              label: (
                                <Tooltip title="Display Second">
                                  <Badge color={getBudgeColor('medium')} />
                                </Tooltip>
                              ),
                            },
                            {
                              value: 'low',
                              label: (
                                <Tooltip title="Display Third">
                                  <Badge color={getBudgeColor('low')} />
                                </Tooltip>
                              ),
                            },
                          ]}
                        />
                      </Form.Item>
                    ) : (
                      <div className={styles.badgePadding} />
                    )}
                    <Form.Item
                      {...restField}
                      name={[name, valueName]}
                      className={styles.input}
                      rules={[{
                        required: true,
                        message: 'Should be at least one character long!',
                      }, {
                        max: 30, message: 'Maximum number of characters - 30',
                      }]}
                    >
                      <Input placeholder={placeholder} /* disabled={checkIsRequiredResume(key)} */ />
                    </Form.Item>
                    {listName === 'experiences' && (
                      <Dropdown
                        itemId={items?.data[key]?.id || ''}
                        itemPriority={items?.data[key]?.priority || ''}
                      />
                    )}
                    <Button
                      className={styles.remove}
                      type="text"
                      loading={deleteAction.loading || validateDomain.loading}
                      /* disabled={checkIsRequiredResume(key)} */
                      onClick={(e) => {
                        e.preventDefault();

                        if (listName === 'domains') {
                          validateDomain
                            .fetch(undefined, items?.data[key].id || '')
                            .then((data) => {
                              open({
                                icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
                                title: removeTitle,
                                content: (
                                  <div>
                                    {!data?.success ? (
                                      <Typography.Paragraph strong>
                                        This Surgeon has active shifts assigned to assistants.
                                        Please remove active locations and try again
                                      </Typography.Paragraph>
                                    ) : (
                                      <Typography.Paragraph>
                                        Are you sure you want remove
                                        {` ${items?.data && items.data[key].title}`}
                                        ?
                                      </Typography.Paragraph>
                                    )}
                                  </div>
                                ),
                                cancelText: 'Cancel',
                                okText: removeButtonText,
                                okButtonProps: { disabled: !data?.success },
                                centered: true,
                                onOk: () => {
                                  if (items) {
                                    deleteAction.fetch(items?.data[key].id || '');
                                  }
                                },
                              });
                            });

                          return;
                        }

                        open({
                          icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
                          title: removeTitle,
                          content: `Are you sure you want remove (${items?.data && items.data[key].title})?`,
                          cancelText: 'Cancel',
                          okText: removeButtonText,
                          centered: true,
                          onOk: () => {
                            if (items) {
                              deleteAction.fetch(items?.data[key].id || '');
                            }
                          },
                        });
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>

                  {requiredCheckbox && (
                    <Form.Item
                      className={styles.checkbox}
                      {...restField}
                      name={[name, 'isRequired']}
                      valuePropName="checked"
                    >
                      <Checkbox>Required during registration?</Checkbox>
                    </Form.Item>
                  )}
                </div>
              ))
              : null)}
          </Form.List>
        )}
      </div>

      <Form.Item className={styles.add}>
        <Button ghost type="primary" onClick={() => setModalOpen(true)} icon={<PlusOutlined />}>
          {nameButton}
        </Button>
      </Form.Item>

      {listName !== 'domains' && <Pagination items={items as PagingDataResponse<Item>} />}
      {contextHolder}
    </>
  );
}

FormList.defaultProps = {
  fetch: undefined,
  items: undefined,
  loading: false,
  requiredCheckbox: false,
};

export default FormList;
